import React, { Component } from "react"
import { Result, Button } from "antd"
import firebase from "../../firebase"
import util from "util"
import axios from "axios"

class CTResult extends Component {
  constructor(props) {
    super(props)
    this.state = {
      chosenDesignId: props.chosenDesignId,
      chosenDesign: props.chosenDesign,
      userId: props.userId,
      userEmail: props.userEmail,
      taskName: props.taskName,
      taskBrief: props.taskBrief,
      isSuccessful: props.isSuccessful
    }
  }

  async componentDidMount() {
    window.document.getElementsByClassName("ct-prev")[0].style.display = "none"
    window.document.getElementsByClassName("ant-steps")[0].style.display =
      "none"
  }

  render() {
    return (
      <div className="aligncenter keep-line-breaks">
        {this.state.isSuccessful && (
          <Result
            status="success"
            title="Task submitted Successfully!"
            subTitle="Our design team will review your task shortly."
            extra={[
              <Button href="/" type="primary" key="mytasks">
                My Tasks
              </Button>,
              <Button href="/create/task" key="createtask">
                Create new task
              </Button>
            ]}
          />
        )}

        {!this.state.isSuccessful && (
          <Result
            status="warning"
            title="There was an issue with your task submission. Please contact support."
            extra={[
              <Button href="/" type="primary" key="mytasks">
                My Tasks
              </Button>,
              <Button href="/create/task" key="createtask">
                Create new task
              </Button>
            ]}
          />
        )}
      </div>
    )
  }
}

export default CTResult
