import React, { Component } from "react";
import { Link } from 'react-router-dom'

class ErrorPage extends Component {
	render() {
		return (
			<div className="error-page">
				<div className="page-text">
					Page Not Found
				</div>
				<div className="button-wrapper">
					<Link to="/">Go to Home</Link>
				</div>
			</div>
		);
	}
}

export default ErrorPage;