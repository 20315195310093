import React from 'react'
import ReactDOM from 'react-dom'
import 'antd/dist/antd.css'
// import './index.css'
import { Comment, Avatar, Form, Button, List, Input } from 'antd'
import moment from 'moment'
import axios from 'axios'
import util from 'util'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'

const { TextArea } = Input

const CommentList = ({ comments }) => (
  <List
    dataSource={comments}
    header={`${comments.length} ${comments.length > 1 ? 'replies' : 'reply'}`}
    itemLayout="horizontal"
    renderItem={props => <Comment {...props} />}
  />
)

const Editor = ({ onChange, onSubmit, submitting, value, onSubmitRefresh }) => (
  <div>
    <Form.Item>
      <TextArea rows={4} onChange={onChange} value={value} />
    </Form.Item>
    <Form.Item>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Button
            htmlType="submit"
            loading={submitting}
            onClick={onSubmit}
            type="primary"
          >
            Add Comment
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button onClick={onSubmitRefresh} type="primary">
            Refresh
          </Button>
        </Grid>
      </Grid>
    </Form.Item>
  </div>
)

class TaskComments extends React.Component {
  constructor(props) {
    super(props)
  }

  state = {
    comments: [],
    submitting: false,
    value: '',
    task: {},
    user: {}
  }

  componentDidMount() {
    const igloo_user = JSON.parse(localStorage.getItem('igloo_user'))
    this.setState({ task: this.props.task, user: igloo_user })
  }

  sendComment(taskId, text) {
    console.log(`https://app.asana.com/api/1.0/tasks/${taskId}/stories`)

    return axios({
      method: 'POST',
      url: `https://app.asana.com/api/1.0/tasks/${taskId}/stories`,
      headers: {
        Authorization: `Bearer 0/4089f7d1f889af6814aabe2213be272c`,
        'Content-Type': 'application/json'
      },
      data: {
        data: { text: text }
      }
    })
      .then(resOut => {
        // this.forceUpdate()
        this.props.handleRefresh()
        return resOut.data.data
      })
      .catch(resOutErr =>
        console.log(`error - ${util.inspect(resOutErr.data)}`)
      )
  }

  handleSubmit = () => {
    if (!this.state.value) {
      return
    }

    this.setState({
      submitting: true
    })

    setTimeout(() => {
      const message = this.state.value
      const task = this.state.task

      this.sendComment(task.gid, message)

      this.setState({
        submitting: false,
        value: '',
        comments: [
          // {
          //   author: 'Han Solo',
          //   avatar:
          //     'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
          //   content: <p className="alignleft">{this.state.value}</p>,
          //   datetime: moment().fromNow()
          // },
          // ...this.state.comments
        ]
      })

      // this.sendComment('1126840867521630', this.state.value)
    }, 1000)
  }

  handleChange = e => {
    this.setState({
      value: e.target.value
    })
  }

  handleBtnRefresh = () => {
    this.props.handleRefresh()
  }

  render() {
    const { comments, submitting, value, user } = this.state

    return (
      <div>
        {comments.length > 0 && <CommentList comments={comments} />}
        <Comment
          avatar={
            <Avatar
              src="/static/media/portrait_placeholder.4788048f.png"
              alt={this.state.user.name}
            />
          }
          content={
            <Editor
              onChange={this.handleChange}
              onSubmit={this.handleSubmit}
              onSubmitRefresh={this.handleBtnRefresh}
              submitting={submitting}
              value={value}
            />
          }
        />
      </div>
    )
  }
}

export default TaskComments
