import React, { Component } from 'react'
import util from 'util'
import DropBoxer from '../../../helpers/dropbox'

class Files extends Component {
  constructor(props) {
    super(props)
  }

  state = {
    files: []
  }

  componentDidMount() {
    const dbxer = new DropBoxer()
    dbxer.listFiles().then(files => {
      console.log(files)

      this.setState({ files: files })
    })
  }

  render() {
    const { files } = this.state

    return (
      <div>
        <div>
          {files.map(file => {
            return `
              
              tag: ${file['.tag']}
              name: ${file.name}
              
            `
          })}
        </div>
      </div>
    )
  }
}

export default Files
