import React, { useState } from 'react'
import {
  Typography,
  Paper,
  Avatar,
  Button,
  FormControl,
  Input,
  InputLabel
} from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import withStyles from '@material-ui/core/styles/withStyles'
import { Link, withRouter } from 'react-router-dom'
import CustomButton from '../GlobalUi/CustomButton'
import { CircularProgress } from '@material-ui/core'
import { Divider } from 'antd'
import { Row, Col } from 'antd'
import firebase from '../firebase'

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    backgroundColor: '#ff9966',
    color: '#fff'
  }
})

function handleForgotPasswordInit() {
  console.log('handleForgotPasswordInit')

  window.document.getElementById('password-wrapper').style.display = 'none'
  window.document.getElementById('login-submit').style.display = 'none'
  window.document.getElementById('forgot-submit').style.display = 'block'
}

function handleForgotPassword() {
  console.log('handleForgotPassword')

  const emailAddress = window.document.getElementById('email').value

  firebase.auth
    .sendPasswordResetEmail(emailAddress)
    .then(function(resOut) {
      console.log(resOut)
      // Email sent.
      setTimeout(() => {
        alert('Password reset link will be sent to your email.')
        window.location.reload()
      }, 1000)
    })
    .catch(function(error) {
      console.log(error)

      setTimeout(() => {
        alert(
          'There was an problem sending reset link. Please contact support.'
        )

        window.location.reload()
      }, 1000)

      // An error happened.
    })
}

function SignIn(props) {
  const { classes } = props

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)

  // var userB = firebase.auth().currentUser
  // userB.updatePassword('pebbledtest').then(() => {
  //   console.log('Password updated!')
  // })

  return (
    <div>
      {loading && (
        <div id="loader" className="ontop-loader">
          <CircularProgress />
        </div>
      )}
      <main className={classes.main}>
        <Paper className={classes.paper}>
          {/* <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar> */}
          <a className="login-logo-a" href="/login">
            <img className="login-logo" src="/logos/with-text/1.png" />
          </a>
          {/* <Typography component="h1" variant="h5">
          Sign in
        </Typography> */}
          <form
            className={classes.form}
            onSubmit={e => e.preventDefault() && false}
          >
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="email">Email Address</InputLabel>
              <Input
                id="email"
                name="email"
                autoComplete="off"
                autoFocus
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </FormControl>

            <FormControl
              id="password-wrapper"
              margin="normal"
              required
              fullWidth
            >
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                name="password"
                type="password"
                id="password"
                autoComplete="off"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </FormControl>
            <Button
              id="login-submit"
              type="submit"
              fullWidth
              variant="contained"
              // color="primary"
              onClick={login}
              className={classes.submit + ' login-btn-submit'}
            >
              Sign in
            </Button>

            <Button
              id="forgot-submit"
              // type="submit"
              fullWidth
              variant="contained"
              // color="primary"
              onClick={e => handleForgotPassword(e.target.value)}
              className={classes.submit + ' login-btn-submit forgot-btn-submit'}
            >
              Send Reset Link
            </Button>

            {/* <CustomButton title="SIGN IN" type="submit" onClick={login} /> */}

            {/* <Button
            fullWidth
            variant="contained"
            // color="primary"
            onClick={login}
            className={classes.submit + ' login-btn-submit'}
          >
            Forgot password
          </Button> */}

            <Divider />

            <Row>
              <Col span={8}>
                <a onClick={handleForgotPasswordInit}>
                  <span>Forgot password</span>
                </a>
              </Col>
              <Col span={8} className="aligncenter">
                <Divider type="vertical" />
              </Col>
              <Col span={8}>
                <a href="https://pebbled.io/">
                  <span>Go to Pebbled</span>
                </a>
              </Col>
            </Row>
          </form>
        </Paper>
      </main>
    </div>
  )

  async function login() {
    try {
      setLoading(true)
      // console.log(`email: ${email}`)
      await firebase.login(email, password)
      setLoading(false)
      props.history.replace('/')
    } catch (error) {
      setLoading(false)
      alert(error.message)
    }
  }
}

export default withRouter(withStyles(styles)(SignIn))
