import React, { Component } from "react"
import classNames from "classnames"
import Grid from "@material-ui/core/Grid"
// import CustomButton from '../components/GlobalUi/CustomButton'
import util from "util"
import Stepper from "../../components/CreateTask/Stepper"
import Header from "../../components/Header"

class CreateTask extends Component {
  constructor(props) {
    super(props)
  }

  state = {
    current: null
  }

  componentDidMount() {
    console.log("this.props.location.propsCurrent")
    console.log(this.props.location.propsCurrent)

    if (this.props.location.propsCurrent) {
      this.setState({ current: this.props.location.propsCurrent })
    }
  }

  render() {
    return (
      <div className="component-wrapper">
        {this.state.current && <Stepper current={this.state.current} />}
        {!this.state.current && <Stepper current="0" />}
      </div>
    )
  }
}

export default CreateTask
