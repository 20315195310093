import React, { useEffect, useState } from 'react'
import {
  Typography,
  Paper,
  Avatar,
  CircularProgress,
  Button
} from '@material-ui/core'
import VerifiedUserOutlined from '@material-ui/icons/VerifiedUserOutlined'
import withStyles from '@material-ui/core/styles/withStyles'
import firebase from '../firebase'
import { withRouter } from 'react-router-dom'
import util from 'util'
import MainApp from '../../MainApp'

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  }
})

// async function setLocalStorage(email) {
//   console.log(`setLocalStorage(${email})`)

//   let self = this
//   try {
//     return await fetch(
//       `https://get-existing-user-info-igloo-api-v2.now.sh/?email=${
//         email
//       }`
//     )
//       .then(resData => resData.json())
//       .then(resData => {
//         const user_obj = {
//           email: resData.email,
//           id: resData.userid,
//           name: resData.name,
//           plan: resData.onboarding.user.plan,
//           project_id: resData.onboarding.projects.asana.projectId,
//           status: resData.onboarding.user.status,
//           cust_id: window.btoa(resData.onboarding.payment.stripe.customerId)
//         }

//         localStorage.setItem('igloo_user', JSON.stringify(user_obj))

//         return true
//       })
//   } catch (err) {
//     console.log('error getting user info')
//     console.log(util.inspect(err))

//     return false
//   }
// }

function Dashboard(props) {
  const { classes } = props
  const [localStoreSuccess, setlocalStoreSuccess] = useState(true)
  let isLoggedIn = false

  try {
    if (firebase.auth.currentUser.uid) {
      isLoggedIn = true
    } else {
      isLoggedIn = false
      props.history.replace('/login')
    }
  } catch (error) {
    isLoggedIn = false
    props.history.replace('/login')
  }

  const [quote, setQuote] = useState('')

  useEffect(() => {
    return function efx() {
      try {
        return firebase.setStorage(firebase.auth.currentUser.uid)
      } catch (error) {
        return null
      }
    }
  }, [])

  return (
    <main>{localStoreSuccess && isLoggedIn && <MainApp />}</main>

    /*<main className={classes.main}>
			<Paper className={classes.paper}>
				<Avatar className={classes.avatar}>
					<VerifiedUserOutlined />
				</Avatar>
				<Typography component="h1" variant="h5">
					Hello { firebase.getCurrentUsername() }
				</Typography>
				<Typography component="h1" variant="h5">
					Your quote: {quote ? `"${quote}"` : <CircularProgress size={20} />}
				</Typography>
				<Button
					type="submit"
					fullWidth
					variant="contained"
					color="secondary"
					onClick={logout}
					className={classes.submit}>
					Logout
          		</Button>
			</Paper>
		</main>*/
  )

  async function logout() {
    await firebase.logout()
    return props.history.push('/')
  }
}

export default withRouter(withStyles(styles)(Dashboard))
