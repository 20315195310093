import React, { Component } from 'react'
import classNames from 'classnames'
import Grid from '@material-ui/core/Grid'
// import CustomButton from '../components/GlobalUi/CustomButton'
import util from 'util'

class Billing extends Component {
  constructor(props) {
    super(props)
  }

  state = {
    user: {}
  }

  componentDidMount() {
    const igloo_user = JSON.parse(localStorage.getItem('igloo_user'))
    this.setState({
      user: igloo_user
    })

    const cust_id = () => window.atob(this.state.user.cust_id)

    // console.log('user')
    // console.log(util.inspect(igloo_user))

    var aScript = document.createElement('script')
    aScript.type = 'text/javascript'
    aScript.src = 'https://chargedesk.com/client.js'

    document.head.appendChild(aScript)
    aScript.onload = function() {
      // document.getElementById('billing-history').InnerHTML = '<div></div>'

      window.ChargeDesk.embed({
        id: 'billing-history', // ID of the HTML div
        company: 'pebbled',
        customer: window.atob(igloo_user.cust_id),
        embed: 'history',
        options: {
          noneText: 'No payments found',
          supportLinks: true,
          showSubscriptions: 'active'
        }
      })
    }
  }

  render() {
    return (
      <div className="component-wrapper">
        {/* <div>Billing History</div> */}
        <div id="billing-history" />
      </div>
    )
  }
}

export default Billing
