import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import AppBar from '@material-ui/core/AppBar'
// import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Discus from './Discus'
import Timeliner from './Timeliner'
import TaskComments from './TaskComments'
import Paper from '@material-ui/core/Paper'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Card from '@material-ui/core/Card'
import axios from 'axios'
import util from 'util'
import { Tabs } from 'antd'
import Overview from './Overview'
import Review from './Review'
import Files from './Files'
// import Files2 from './File2'

const { TabPane } = Tabs

function callback(key) {
  // console.log(key)
}

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%'
    // backgroundColor: theme.palette.background.paper
  }
}))

export default function SimpleTabs(props) {
  const asana_token = '0/4089f7d1f889af6814aabe2213be272c'
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const [files, setFiles] = useState(0)
  //   setFiles()

  // useEffect(() => {
  //   return getFilesFunc(props.task)
  // }, [])

  function handleChange(event, newValue) {
    setValue(newValue)
  }

  const notesToClient = task => {
    const custFields = task.custom_fields
    let custNotesOut = null

    for (let x of custFields) {
      if (x.name === 'Notes to Client' || x.gid === '588591804552327') {
        // got client deliverable notes
        if (x.text_value && x.text_value.length > 0) {
          custNotesOut = x.text_value
        } else {
          custNotesOut = 'No client notes found!'
        }
      }
    }

    // console.log('notesToClient')
    // console.log(custNotesOut)

    return custNotesOut
  }

  const getFileFunc = async attachment_gid => {
    // /attachments/${attachment_gid}
    // console.log(`https://app.asana.com/api/1.0/attachments/${attachment_gid}`)

    const out = await axios({
      method: 'GET',
      url: `https://app.asana.com/api/1.0/attachments/${attachment_gid}`,
      //   url: `https://app.asana.com/api/1.0/attachments/1131691741103016`,
      headers: {
        Authorization: `Bearer ${asana_token}`,
        'Content-Type': 'application/json'
      }
    }).then(resOut => resOut.data.data)

    // console.log(`single attachment`)
    // console.log(util.inspect(out))
    return out
  }

  function getFilesFunc(task) {
    // console.log(util.inspect(task.gid))

    // return 'ok'
    //
    const taskFilesReq = axios({
      method: 'GET',
      url: `https://app.asana.com/api/1.0/tasks/${task.gid}/attachments`,
      headers: {
        Authorization: `Bearer ${asana_token}`,
        'Content-Type': 'application/json'
      }
    })
      .then(resOut => {
        const taskFiles = resOut.data.data

        // console.log(`ALL attachments`)
        // console.log(util.inspect(taskFiles))

        let filesUrlsReqs = []

        async function filesX() {
          const _out = []

          for (let x of taskFiles) {
            const _temp = await getFileFunc(x.gid)
              .then(aRes => {
                return { url: aRes.view_url, data: aRes }
              })
              .catch(aRes => {
                return { url: aRes.download_url, data: aRes }
              })

            // .then(aRes => aRes.view_url)
            // .catch(aRes => aRes.download_url)

            // console.log(`_out - ${_temp}`)
            _out.push(_temp)
          }

          setFiles(_out)
          // return _out
        }

        filesUrlsReqs = filesX()

        // console.log(`filesUrlsReqs`)
        // console.log(util.inspect(filesUrlsReqs))

        const out = filesUrlsReqs[0]
        //   setFiles(filesUrlsReqs[0])
      })
      .catch(err => {
        // console.log(`taskFilesReq err - ${err}`)
        // getFilesFunc(task)
      })

    return 'Loading..'
  }

  const deliverableLink = task => {
    const custFields = task.custom_fields
    let custNotesOut = null

    for (let x of custFields) {
      if (x.name === 'Deliverable Link' || x.gid === '588591804552325') {
        // got client deliverable links
        if (x.text_value && x.text_value.length > 0) {
          custNotesOut = x.text_value
        } else {
          custNotesOut = 'No delivery links found!'
        }
      }
    }

    // console.log('notesToClient')
    // console.log(custNotesOut)

    return custNotesOut
  }

  return (
    <div className={classes.root}>
      <Tabs
        className="alignleft"
        defaultActiveKey="1"
        value={value}
        onChange={handleChange}
      >
        {/* <TabPane tab="Review" key="1" className="hideme">
          <TabContainer>
            <Review task={props.task} />
          </TabContainer>
        </TabPane> */}
        <TabPane tab="Overview" key="1">
          <TabContainer>
            <Overview task={props.task} />
          </TabContainer>
        </TabPane>
        {/* <TabPane tab="Files" key="3" className="hideme">
          <TabContainer>
            <Files2 />
            <iframe
              src="https://kadukeitor.github.io/files-fire"
              width="100%"
              height="600"
              frameborder="0"
            />
          </TabContainer>
        </TabPane> */}
        {/* <TabPane tab="Discussion" key="4" className="hideme">
          <TabContainer>
            <iframe
              // src={`https://chat.pebbled.io/room/${props.task.gid}`}
              src={`http://localhost:4172/room/${props.task.gid}`}
              width="100%"
              height="600"
              frameBorder="0"
            />
          </TabContainer>
        </TabPane> */}

        {/* <TabPane label="Review" /> */}
        {/* 
          <Tab label="Revisions" />
          <Tab label="Timeline" />
          <Tab label="TaskComments antd" />
          <Tab label="Timeline" /> 6
          <Tab label="Files" /> 7
          */}
      </Tabs>

      {/* <AppBar position="static">
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Overview" />
          <Tab label="Review" />
          
          <Tab label="Revisions" />
          <Tab label="Timeline" />
          <Tab label="TaskComments antd" />
          <Tab label="Timeline" /> 6
          <Tab label="Files" /> 7
         
        </Tabs>
      </AppBar> */}
      {/* {value === 0 && (
        <TabContainer>
          <div> Ready to Review 2</div>
        </TabContainer>
      )}
      {value === 1 && (
        <TabContainer>
          <div> Review 2</div>
        </TabContainer>
      )}
      {value === 2 && (
        <TabContainer>
          <Discus />
        </TabContainer>
      )}
      {value === 3 && (
        <TabContainer>
          <Timeliner task={props.task} />
        </TabContainer>
      )}
      {value === 4 && (
        <TabContainer>
          <TaskComments />
        </TabContainer>
      )}

      {value === 5 && (
        <TabContainer className="fullwidth fullheight">
          <img
            src="https://projects.invisionapp.com/static-signed/live-embed/280464490/374493971/1/latest/Y9revWAXSGmauNDcakJlQUCIVL4ijaHFcrnAP7vvlEFzaqglqUtzWRVR8blECNlEIPofUGjBxxyFPL5Bq3Rc92zQlE/full-slider2.jpg"
            className="fullwidth fullheight"
          />
        </TabContainer>
      )}

      {value === 6 && (
        <TabContainer>
          <Timeliner task={props.task} />
        </TabContainer>
      )}

      {value === 7 && (
        <TabContainer>
          {files &&
            files.map(x => {
              return (
                <Paper className="alignleft">
                  <pre>{util.inspect(x.data)}</pre>
                  <pre>Name: {x.data.name}</pre>
                  <pre>Created: {x.data.created_at} </pre>
                  <img src={x.url} height="auto" width="400px" title="XYZ" />
                </Paper>
              )
            })}
        </TabContainer>
      )} */}
    </div>
  )
}
