/* eslint-disable no-useless-constructor */
import React, { Component } from 'react'
import util from 'util'
import { Typography, Divider } from 'antd'
import { Row, Col } from 'antd'

class Overview extends Component {
  constructor(props) {
    super(props)
  }

  state = {
    user: {},
    task: {},
    notes: 'No notes...',
    links: 'No links...'
  }

  notesToClient(task) {
    const custFields = task.custom_fields
    let custNotesOut = null

    for (let x of custFields) {
      if (x.name === 'Notes to Client' || x.gid === '588591804552327') {
        // got client deliverable notes
        if (x.text_value && x.text_value.length > 0) {
          custNotesOut = x.text_value
        } else {
          custNotesOut = 'No client notes found!'
        }
      }
    }

    // console.log('notesToClient')
    // console.log(custNotesOut)

    return custNotesOut
  }

  deliverableLink(task) {
    const custFields = task.custom_fields
    let custLinksOut = null

    for (let x of custFields) {
      if (x.name === 'Deliverable Link' || x.gid === '588591804552325') {
        // got client deliverable links
        if (x.text_value && x.text_value.length > 0) {
          custLinksOut = x.text_value
        } else {
          custLinksOut = 'No delivery links found!'
        }
      }
    }

    // console.log('notesToClient')
    // console.log(custNotesOut)

    return custLinksOut
  }

  componentDidMount() {
    const task = this.props.task ? this.props.task : {}

    this.setState({ task: task })

    if (task) {
      const notes = this.notesToClient(task)
      const links = this.deliverableLink(task)

      this.setState({ notes: notes, links: links })
    }
  }

  render() {
    const { notes, links } = this.state
    return (
      <div>
        {/* <Row className="task-review-double-row">
          <Col className="" span={8}>
            <div>Date Created</div>
          </Col>
          <Col span={8}>
            <Divider className="aligncenter" type="vertical" />
          </Col>
          <Col className="" span={8}>
            <div>12.04.2019</div>
          </Col>
        </Row>

        <Row className="task-review-double-row">
          <Col className="" span={8}>
            <div>Team</div>
          </Col>
          <Col span={8}>
            <Divider className="aligncenter" type="vertical" />
          </Col>
          <Col className="" span={8}>
            <div>Design</div>
          </Col>
        </Row>

        <Row className="task-review-double-row">
          <Col className="" span={8}>
            <div>Status</div>
          </Col>

          <Col span={8}>
            <Divider className="aligncenter" type="vertical" />
          </Col>

          <Col className="" span={8}>
            <div>In Progress</div>
          </Col>
        </Row> */}

        <div> Delivered Link/s </div>
        <div>{links}</div>
        <br />
        <div> Delivered Notes </div>
        <div>{notes}</div>
        <br />
      </div>
    )
  }
}

export default Overview
