import React, { Component } from 'react'
import classNames from 'classnames'
import Grid from '@material-ui/core/Grid'
// import CustomButton from '../components/GlobalUi/CustomButton'
import util from 'util'

class Support extends Component {
  constructor(props) {
    super(props)
  }

  state = {
    user: {}
  }

  componentDidMount() {
    const igloo_user = JSON.parse(localStorage.getItem('igloo_user'))
    this.setState({
      user: igloo_user
    })
  }

  render() {
    return (
      <div className="component-wrapper">
        <div id="support">My task chats</div>
      </div>
    )
  }
}

export default Support
