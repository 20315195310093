import React, { Component } from 'react'
import commentBox from 'commentbox.io'

class Discus extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.removeCommentBox = commentBox(`5632418417475584-proj`)
  }

  componentWillUnmount() {
    this.removeCommentBox()
  }

  render() {
    return <div className="commentbox" id={String(this.props.full_id)} />
  }
}

export default Discus
