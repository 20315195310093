import React, { Component } from "react";
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';

class IdForm extends Component {

	state = {
    name: "",
    email: "",
    plan: "",
		isTrial: false,
		trialDaysLeft: "",
		user: ""
  };

	componentDidMount(){
		this.setState({
			name: this.props.name,
			email: this.props.email,
			plan: this.props.plan
		})

		this.setState({
			trialDaysLeft: this.props.trialDaysLeft,
			isTrial: this.props.isTrial
		})

		// if(this.state.user.status.toLowerCase() === 'trialing' || this.state.user.status.toLowerCase() === 'trial'){
		// 	this.setState({
		// 		isTrial: true, trialDaysLeft: this.props.trialDaysLeft
		// 	})
		// }

		// this.isTrial(this.state.user.id)

		// console.log('idForm props')
		// console.log(this.props)
	}

	componentWillMount() {
		let igloo_user = JSON.parse(localStorage.getItem('igloo_user'));
		this.setState({user: igloo_user})

		// this.setState({
		// 	isTrial: this.props.isTrial,
		// 	trialDaysLeft: this.props.trialDaysLeft
		// })
	}

	handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

	Capitalize(str){
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	render() {

		return (
			<div className="form-wrapper">
				<form className="id-form" noValidate autoComplete="off">
					<FormControl className="field-wrap">
	          <span className="label">Name</span>
	          <Input readOnly id="name" name="name" value={this.Capitalize(this.state.name)} onChange={this.handleChange} disabled />
	        </FormControl>
	        <FormControl className="field-wrap">
            <span className="label">Email</span>
            <Input readOnly id="email" name="email" value={this.state.email} onChange={this.handleChange} disabled/>
          </FormControl>
					<FormControl className="field-wrap">
            <span className="label">Plan</span>
            <Input readOnly id="plan" name="plan" value={this.Capitalize(this.state.plan)} onChange={this.handleChange} disabled/>
          </FormControl>

					{this.state.isTrial &&
						<FormControl className="field-wrap">
							<span className="label">Trial Days Remaining</span>
							<Input readOnly id="trial" name="trial" value={this.props.trialDaysLeft} onChange={this.handleChange} disabled/>
						</FormControl>
					}
				</form>
			</div>
		);
	}
}

export default IdForm;
