import React, { Component } from "react"
import { Steps, Button, message } from "antd"
import { Skeleton } from "antd"
import util from "util"
import uuidv4 from "../../helpers/uuidv4"
import firebase from "./../firebase"
import getClosest from "../../helpers/getClosest"
import extractSubstring from "../../helpers/extractSubstring"
import { Input } from "antd"
import { CircularProgress } from "@material-ui/core"
import BriefUpload from "./BriefUpload"
import Confirm from "./Confirm"
import { Typography } from "antd"
import { Row, Col } from "antd"
import { Divider } from "antd"
import { Card } from "antd"
import _ from "lodash"
import {
  chooseDesignsTop,
  chooseDesignsPrint,
  chooseDesignsCorporateIdentity,
  chooseDesignsWeb,
  chooseDesignsMerchandise,
  chooseDesignsGraphic,
  chooseDesignsArtIllustration,
  chooseDesignsOther
} from "./data"

const { TextArea } = Input

const { Meta } = Card

const { Title } = Typography
const { Text } = Typography
const { Step } = Steps

const steps = [
  {
    title: "Name",
    content: "First-content"
  },
  {
    title: "Choose Task",
    content: "Second-content"
  },
  {
    title: "Brief",
    content: "Last-content"
  }
]

class Stepper extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      current: 0,
      chosenDesign: null,
      chosenDesignId: null,
      taskName: "",
      taskBrief: "",
      delivery: {},
      value: "",
      loading: false
    }
  }

  // state = {

  // };

  async componentDidMount() {
    this.setState({
      userId: await firebase.auth.currentUser.uid,
      userEmail: await firebase.auth.currentUser.email
    })

    try {
      const newCurrent = this.props.current
      this.setState({ current: parseInt(newCurrent) })
    } catch (error) {
      //
    }

    // let intTime = 10000
    // try {
    //   if (this.state.current === 1) {
    //     if (
    //       !window.document.getElementById("taskBrief").value == "" ||
    //       !window.document.getElementById("taskName").value == ""
    //     ) {
    //       setInterval(function() {
    //         this.setState(
    //           {
    //             taskBrief: window.document.getElementById("taskBrief").value,
    //             taskName: window.document.getElementById("taskName").value
    //           },
    //           () =>
    //             this.saveBriefToFirebase(
    //               this.state.taskName,
    //               this.state.taskBrief
    //             )
    //         )
    //       }, intTime)
    //     }
    //   } else {
    //     intTime = 999999999
    //   }
    // } catch (error) {
    //   intTime = 999999999
    // }
  }

  async saveBriefToFirebase(taskName, taskBrief) {
    const { chosenDesign, chosenDesignId, userId, userEmail } = this.state

    await firebase.db
      .collection("createTasks")
      .doc("user")
      .collection(userId)
      .doc(chosenDesignId)
      .set(
        {
          id: chosenDesignId,
          name: taskName,
          chosenDesign: chosenDesign,
          description: taskBrief,
          status: "draft",
          userId: userId,
          email: userEmail
        },
        { merge: true }
      )
      .then(function() {
        console.log("Document successfully written!")
      })
      .catch(function(error) {
        console.error("Error writing document: ", error)
      })
  }

  next() {
    if (this.state.current === 1) {
      if (window.document.getElementById("taskBrief").value == "") {
        alert("Please fill in task description.")

        return
      }

      if (window.document.getElementById("taskName").value == "") {
        alert("Please fill in task name.")

        return
      }
    }

    const current = this.state.current + 1
    this.setState({ current })

    if (this.state.current === 0) {
      // moving from chosen to brief
      this.hydrateBrief()
    }

    if (this.state.current === 1) {
      // we are moving to confirm
      // save brief shit to firebase yo

      this.setState(
        {
          taskBrief: window.document.getElementById("taskBrief").value,
          taskName: window.document.getElementById("taskName").value
        },
        () =>
          this.saveBriefToFirebase(this.state.taskName, this.state.taskBrief)
      )
    }
  }

  prev() {
    let saveData = false

    if (this.state.current === 1) {
      if (
        window.document.getElementById("taskBrief").value == "" ||
        window.document.getElementById("taskName").value == ""
      ) {
        saveData = false
      } else {
        saveData = true
      }
    }

    const current = this.state.current - 1

    if (this.state.current === 1 && saveData) {
      // moving from brief to choose design
      // clear fields

      const taskName = window.document.getElementById("taskName").value
      const taskBrief = window.document.getElementById("taskBrief").value

      console.log("< moving from brief to choose design")

      console.log(util.inspect(taskBrief))

      this.setState(
        {
          taskBrief: "",
          taskName: ""
        },
        () => this.saveBriefToFirebase(taskName, taskBrief)
      )

      // this.setState({ taskName: "", taskBrief: "" })
      // this.hydrateBrief("all")
    }

    if (this.state.current === 2) {
      // moving from confirm to brief
      // this.hydrateBrief("all")
    }

    this.setState({ current })
  }

  onChange() {
    // const {current} = this.state
    // console.log('onChange:', current)
    // this.setState({ current })
  }

  onChangeBrief = ({ target: { value } }) => {
    console.log("onChangeBrief")
    console.log(value)
    this.setState({ value })
  }

  onChangeTaskName() {
    setTimeout(() => {
      this.setState({
        taskName: window.document.getElementById("taskName").value
      })
    }, 200)
  }

  onChangeTaskBriefText() {
    console.log("text description changed")
    setTimeout(() => {
      this.setState({
        taskBrief: window.document.getElementById("taskBrief").value
      })
    }, 200)
  }

  handleClick = e => {
    try {
      let clickedDesignText = null
      let clickedDesignId = null

      if (e.target.innerText === "") {
        console.log("e.target.innerText empty")
        if (e.target.getAttribute("alt") !== "") {
          console.log(e.target.getAttribute("alt"))
          clickedDesignText = e.target.getAttribute("alt")
          clickedDesignId = getClosest(e.target, ".ct-root").id
        }
      } else {
        console.log("e.target.innerText NOT empty")
        clickedDesignText = e.target.innerText
        clickedDesignId = getClosest(e.target, ".ct-root").id

        console.log("handleClick:", e.target.innerText)
        console.log("clickedDesignId:", clickedDesignId)
      }

      if (clickedDesignText && clickedDesignId) {
        console.log("clickedDesignText && clickedDesignId = true")
        console.log(clickedDesignText)
        console.log(clickedDesignId)

        this.setState(
          {
            chosenDesign: clickedDesignText,
            chosenDesignId: clickedDesignId
          },
          () => {
            this.next()
          }
        )
      } else {
        console.log("clickedDesignText && clickedDesignId = false")
      }
    } catch (error) {
      console.log("unable to get e.target.innerText")
    }

    console.log(this.state.chosenDesign)
  }

  async hydrateBrief(nameOrBrief) {
    const self = this
    const { userId, chosenDesignId } = this.state

    let name = null
    let description = null

    this.setState({ loading: true })

    await firebase.db
      .doc(`createTasks/user/${this.state.userId}/${this.state.chosenDesignId}`)
      .get()
      .then(function(doc) {
        if (doc.exists) {
          const docData = doc.data()
          console.log("Document data:", doc.data())

          name = docData.name
          description = docData.description

          self.setState({ taskName: name, taskBrief: description })
        } else {
          console.log("No such document!")
          self.setState({ taskName: "", taskBrief: "" })
        }
      })
      .catch(function(error) {
        console.log("Error getting document:", error)
      })

    this.setState({ loading: false })

    if (nameOrBrief === "name") {
      return name
    } else if (nameOrBrief === "description") {
      return description
    } else {
      return { name, description }
    }
  }

  render() {
    const { current } = this.state
    const { value } = this.state

    return (
      <div className="alignleft">
        {/* <Steps current={current} onChange={this.onChange}>
          {steps.map(item => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps> */}
        <Steps current={current} onChange={this.onChange()}>
          <Step title="Choose design" />
          <Step title="Task Brief" />
          <Step title="Confirm" />
        </Steps>

        <div className="steps-content">
          {current === 0 && (
            <div className="create-task-steps-root">
              <Title>What design do you need?</Title>
              <h3>Select the type of design you need designed</h3>

              {/* 
              Social Media Posts //
              Advertisement //
              Logo Design //
              Business Card Design //
              Letterhead Design //
              Stationary //
              E-Book Cover //
              Infographic //
              Flyer //
              Brochure //
              Packaging //
              T-shirt //
               */}

              <Divider dashed />

              <Row
                align="middle"
                className="marg-auto"
                onClick={this.handleClick}
              >
                {chooseDesignsTop.map(taskIcon => (
                  <div id={taskIcon.id} key={taskIcon.id} className="ct-root">
                    <Col xs={{ span: 24 }} md={{ span: 9 }} lg={{ span: 6 }}>
                      <Card
                        hoverable
                        style={{ width: 240 }}
                        value={taskIcon.name}
                        cover={
                          <img alt={taskIcon.name} src={taskIcon.iconSrc} />
                        }
                      >
                        <Text className="choose-font-size" strong>
                          {taskIcon.name}
                        </Text>
                      </Card>
                    </Col>
                  </div>
                ))}
              </Row>
            </div>
          )}

          {current === 1 && (
            // step 2 create brief

            <div>
              {this.state.loading && (
                <div className="loader-padding">
                  {/* <CircularProgress /> */}
                  <Skeleton active />
                  <Divider />
                  <Skeleton active />
                  <Divider />
                  <Skeleton active />
                  <Divider />
                </div>
              )}

              {!this.state.loading && (
                <div className="create-task-steps-brief">
                  <Title>
                    Describe the{" "}
                    {this.state.chosenDesign.includes("Design") ||
                    this.state.chosenDesign.includes("design")
                      ? this.state.chosenDesign
                      : `${this.state.chosenDesign} design`}{" "}
                    you need
                  </Title>
                  <h3>
                    Let's get started with some basic information about your
                    task
                  </h3>
                  <Divider dashed />
                  <div className="brief-input-wrapper">
                    <h3>Name your task</h3>
                    <TextArea
                      id="taskName"
                      onChange={() => this.onChangeTaskName()}
                      placeholder="My awesome task name"
                      defaultValue={this.state.taskName}
                      autoSize
                    />
                  </div>
                  <div style={{ margin: "24px 0" }} />
                  <div className="brief-input-wrapper">
                    <h3>Task description</h3>
                    <TextArea
                      allowClear="true"
                      id="taskBrief"
                      onChange={() => this.onChangeTaskBriefText()}
                      placeholder=""
                      defaultValue={this.state.taskBrief}
                      autoSize={{ minRows: 10, maxRows: 1000 }}
                    />
                  </div>

                  <div style={{ margin: "24px 0" }} />
                  <div className="brief-input-wrapper">
                    <h3>Upload files (optional)</h3>
                  </div>
                  <BriefUpload
                    chosenDesignId={this.state.chosenDesignId}
                    userId={this.state.userId}
                  />
                </div>
              )}
            </div>
          )}

          {current === 2 && (
            <div className="create-task-steps-brief ct-confirm">
              <Confirm
                chosenDesignId={this.state.chosenDesignId}
                chosenDesign={this.state.chosenDesign}
                userId={this.state.userId}
                userEmail={this.state.userEmail}
                taskName={this.state.taskName}
                taskBrief={this.state.taskBrief}
              />
            </div>
          )}
        </div>

        {current === 0 && (
          <div className="steps-content">
            <div className="create-task-steps-brief">
              <Row className="alignleft flex-wrap">
                {/* <Col span={6}> */}
                <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                  <h2 className="bold">Prints</h2>
                  {chooseDesignsPrint.map(des => (
                    <div id={des.id} key={des.id} className="ct-root">
                      <Text
                        className="choose-font-size hyper"
                        strong
                        onClick={this.handleClick}
                      >
                        {des.name}
                      </Text>
                      <br />
                    </div>
                  ))}
                </Col>

                {/* <Col span={6}> */}
                <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                  <h2 className="bold">Brand Identity</h2>
                  {chooseDesignsCorporateIdentity.map(des => (
                    <div id={des.id} key={des.id} className="ct-root">
                      <Text
                        className="choose-font-size hyper"
                        strong
                        onClick={this.handleClick}
                      >
                        {des.name}
                      </Text>
                      <br />
                    </div>
                  ))}
                </Col>

                {/* <Col span={6}> */}

                <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                  <h2 className="bold">Web</h2>
                  {chooseDesignsWeb.map(des => (
                    <div id={des.id} key={des.id} className="ct-root">
                      <Text
                        className="choose-font-size hyper"
                        strong
                        onClick={this.handleClick}
                      >
                        {des.name}
                      </Text>
                      <br />
                    </div>
                  ))}
                </Col>

                {/* <Col span={6}> */}
                <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                  <h2 className="bold">Graphic</h2>
                  {chooseDesignsGraphic.map(des => (
                    <div id={des.id} key={des.id} className="ct-root">
                      <Text
                        className="choose-font-size hyper"
                        strong
                        onClick={this.handleClick}
                      >
                        {des.name}
                      </Text>
                      <br />
                    </div>
                  ))}
                </Col>

                {/* <Col span={6}> */}
                <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                  <br />
                  <h2 className="bold">Merchandise</h2>
                  {chooseDesignsMerchandise.map(des => (
                    <div id={des.id} key={des.id} className="ct-root">
                      <Text
                        className="choose-font-size hyper"
                        strong
                        onClick={this.handleClick}
                      >
                        {des.name}
                      </Text>
                      <br />
                    </div>
                  ))}
                </Col>

                {/* <Col span={6}> */}
                <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                  <h2 className="bold">Art & Illustration</h2>
                  {chooseDesignsArtIllustration.map(des => (
                    <div id={des.id} key={des.id} className="ct-root">
                      <Text
                        className="choose-font-size hyper"
                        strong
                        onClick={this.handleClick}
                      >
                        {des.name}
                      </Text>
                      <br />
                    </div>
                  ))}
                </Col>

                {/* <Col span={6}> */}
                <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                  <h2 className="bold">Other</h2>
                  {chooseDesignsOther.map(des => (
                    <div id={des.id} key={des.id} className="ct-root">
                      <Text
                        className="choose-font-size hyper"
                        strong
                        onClick={this.handleClick}
                      >
                        {des.name}
                      </Text>
                      <br />
                    </div>
                  ))}
                </Col>
              </Row>
            </div>
          </div>
        )}
        <div className="steps-action">
          {current > 0 && (
            <Button
              className="ct-prev"
              style={{ marginLeft: 8 }}
              onClick={() => this.prev()}
            >
              Previous
            </Button>
          )}

          {current < steps.length - 1 && current > 0 && (
            <Button
              className="ct-next"
              type="primary"
              onClick={() => this.next()}
            >
              Next
            </Button>
          )}
          {/* {current === steps.length - 1 && (
            <Button
              type="primary"
              onClick={() => message.success("Processing complete!")}
            >
              Done
            </Button>
          )} */}
        </div>
      </div>
    )
  }
}

export default Stepper
