export const chooseDesignsTop = [
  {
    name: 'Social Media Posts',
    id: 'ct-d2ab1f27e69c',
    iconSrc: '/create-task/top/01-Social-Media-Posts_PARENT.svg'
  },
  {
    name: 'Advertisement',
    id: 'ct-8161d720fb8c',
    iconSrc: '/create-task/top/02-Advertisement_PARENT.svg'
  },
  {
    name: 'Logo Design',
    id: 'ct-d752edc36f92',
    iconSrc: '/create-task/top/03-Logo-Design_PARENT.svg'
  },
  {
    name: 'Business Card Design',
    id: 'ct-2ca6995d5f78',
    iconSrc: '/create-task/top/04-Business-Card-Design_PARENT.svg'
  },
  {
    name: 'Letterhead Design',
    id: 'ct-093db463f030',
    iconSrc: '/create-task/top/05-Letterhead-Design_PARENT.svg'
  },
  {
    name: 'Stationary',
    id: 'ct-6c1466b04eae',
    iconSrc: '/create-task/top/06-Stationery_PARENT.svg'
  },
  {
    name: 'E-Book Cover',
    id: 'ct-501dadd928dc',
    iconSrc: '/create-task/top/07-eBook-Cover_PARENT.svg'
  },
  {
    name: 'Infographic',
    id: 'ct-0ef5145762f1',
    iconSrc: '/create-task/top/08-Infographic_PARENT.svg'
  },
  {
    name: 'Flyer',
    id: 'ct-ea5a2cbba45b',
    iconSrc: '/create-task/top/09-Flyer-Design_PARENT.svg'
  },
  {
    name: 'Brochure',
    id: 'ct-77f226b256fd',
    iconSrc: '/create-task/top/10-Brochure-Design_PARENT.svg'
  },
  {
    name: 'Packaging',
    id: 'ct-c90e9472d15a',
    iconSrc: '/create-task/top/11-Packaging_PARENT.svg'
  },
  {
    name: 'T-shirt',
    id: 'ct-133d28ea72fe',
    iconSrc: '/create-task/top/12-T-Shirt-Design_PARENT.svg'
  }
]

export const chooseDesignsPrint = [
  { name: 'Advertisement', id: 'ct-c59fddb7ad62' },
  { name: 'Brochure', id: 'ct-ad70f9a6f108' },
  { name: 'Calendar', id: 'ct-bdf89ea77042' },
  { name: 'Catalogue', id: 'ct-b0b9299b48f5' },
  { name: 'CD Cover', id: 'ct-f0615728a4c3' },
  { name: 'eBook Cover', id: 'ct-789737bdd701' },
  { name: 'Envelope', id: 'ct-32659fd86efe' },
  { name: 'Flyer', id: 'ct-521ae673a14c' },
  { name: 'Magazine', id: 'ct-e239c26a15f1' },
  { name: 'Menu', id: 'ct-8657df1972d1' },
  { name: 'Newspaper Ad', id: 'ct-ee09ca561083' },
  { name: 'Packaging', id: 'ct-2dad29301dce' },
  { name: 'Postcard', id: 'ct-2aa30d5aed97' },
  { name: 'Poster', id: 'ct-9c807c38f7ce' },
  { name: 'Resume', id: 'ct-78f99a6a3971' },
  { name: 'Sticker', id: 'ct-156916349343' },
  { name: 'T-shirt', id: 'ct-21dc49060e1f' }
]

export const chooseDesignsCorporateIdentity = [
  { name: 'Logo', id: 'ct-f9c124c0b6bf' },
  { name: 'Business Card', id: 'ct-eb771c050987' },
  { name: 'Billboard', id: 'ct-bbfdec949b29' },
  { name: 'Car Wrap', id: 'ct-1010a9ba46ef' },
  { name: 'Label', id: 'ct-d6b701fb19f2' },
  { name: 'Letterhead', id: 'ct-96228ffea302' },
  { name: 'Name Card', id: 'ct-0e515ca83998' },
  { name: 'Signage', id: 'ct-452d02c4a537' },
  { name: 'Stationery', id: 'ct-53a0687b1ed5' },
  { name: 'Trade Show Booth', id: 'ct-4b0df12e3008' }
]

export const chooseDesignsWeb = [
  { name: 'Web', id: 'ct-6904ec21ed93' },
  { name: 'Banner Ad', id: 'ct-68cae10074ee' },
  { name: 'Blog', id: 'ct-8666aa20136b' },
  { name: 'Email Marketing', id: 'ct-f349542ef5bd' },
  { name: 'Facebook', id: 'ct-d29dbc40326f' },
  { name: 'Icon', id: 'ct-bbbf2d6de80c' },
  { name: 'Landing Page', id: 'ct-3ced577fe04a' },
  { name: 'Newsletter', id: 'ct-2d17d0837c5c' },
  { name: 'Twitter', id: 'ct-caaed942d609' },
  { name: 'YouTube', id: 'ct-97e4916c531b' }
]

export const chooseDesignsMerchandise = [
  { name: 'Apparel', id: 'ct-92ede8b93dc6' },
  { name: 'Bag and Tote', id: 'ct-8a58a419751f' },
  { name: 'Cup and Mug', id: 'ct-e21c6cb44922' },
  { name: 'Merchandise', id: 'ct-48b5935901bf' }
]

export const chooseDesignsGraphic = [
  { name: 'Graphic', id: 'ct-c35ba7925c88' },
  { name: 'App', id: 'ct-9231d682c3d6' },
  { name: 'Infographic', id: 'ct-f09dbf2c625e' },
  { name: 'Photoshop', id: 'ct-5da8171ee998' },
  { name: 'Podcast', id: 'ct-7b4beb825502' },
  { name: 'PowerPoint', id: 'ct-36cc558eb088' },
  { name: 'Vector', id: 'ct-613d24fca9ad' }
]

export const chooseDesignsArtIllustration = [
  { name: 'Art', id: 'ct-dcd50f610251' },
  { name: 'Book Cover', id: 'ct-02ad450c0526' },
  { name: 'Card', id: 'ct-496a59720bff' },
  { name: 'Character', id: 'ct-ed57d46e973b' },
  { name: 'Greeting Card', id: 'ct-1699c26e52cc' },
  { name: 'Illustration', id: 'ct-81823b01ae21' },
  { name: 'Invitation', id: 'ct-75b60c8874b2' },
  { name: 'Tattoo', id: 'ct-6cdaf0cfc23d' }
]

export const chooseDesignsOther = [
  {
    name: 'Graphic',
    id: 'ct-98ccb72ad6bd'
  }
]
