import React, { Component } from "react";

import Popover from "@material-ui/core/Popover";

class Notifications extends Component {
	state = {
		anchorEl: null
	};

	handleClick = event => {
		this.setState({
			anchorEl: event.currentTarget
		});
	};

	handleClose = () => {
		this.setState({
			anchorEl: null
		});
	};

	render() {
		const { anchorEl, name, email } = this.state;
		const open = Boolean(anchorEl);

		return (
			<div className="user-info">
				<div className="user-detail">
					<div
						aria-owns={open ? "user-menu" : undefined}
						aria-haspopup="true"
						variant="contained"
						onClick={this.handleClick}
					>
						<div className="text-wrap">
							Notifications
							<span>5</span>
						</div>
					</div>
					<Popover
						id="user-menu"
						className="popper"
						open={open}
						anchorEl={anchorEl}
						onClose={this.handleClose}
						anchorOrigin={{
					    vertical: 'bottom',
					    horizontal: 'right',
					  }}
					  transformOrigin={{
					    vertical: 'top',
					    horizontal: 'right',
					  }}
					>
						<div className="user-wrapper">
							<ul className="notification-list">
								<li>
									Notifications 1
								</li>
								<li>
									Notifications 2
								</li>
								<li>
									Notifications 3
								</li>
								<li>
									Notifications 4
								</li>
								<li>
									Notifications 5
								</li>
							</ul>
						</div>
					</Popover>
				</div>
			</div>
		);
	}
}

export default Notifications;