// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import React, { Component } from 'react'
import util from 'util'
import axios from 'axios'
import { Typography, Divider, Input } from 'antd'
import { Row, Col } from 'antd'
import CardFile from './CardFile'
import { Button } from 'antd'
import DropBoxer from '../../../helpers/dropbox'
import { CssBaseline, CircularProgress } from '@material-ui/core'

const { Title, Paragraph, Text } = Typography

const { TextArea } = Input

class Edit extends Component {
  constructor(props) {
    super(props)

    let task = this.props.task ? this.props.task : {}
    const innerNotes = this.extractSubstring(
      task.notes,
      '__START_VIEW_IN_IGLOO__',
      '__END_VIEW_IN_IGLOO__'
    )

    task.notes = innerNotes

    this.state.description = task.notes
    this.state.task = task
  }

  state = {
    user: {},
    task: {},
    description: 'No task description...',
    files: [],
    uploadedImages: [],
    loading: false
  }

  extractSubstring(string, prefix, suffix) {
    let s = string
    let i = s.indexOf(prefix)
    if (i >= 0) {
      s = s.substring(i + prefix.length)
    } else {
      return ''
    }
    if (suffix) {
      i = s.indexOf(suffix)
      if (i >= 0) {
        s = s.substring(0, i)
      } else {
        return ''
      }
    }
    return s
  }

  linkify(text) {
    var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi
    return text.replace(urlRegex, function(url) {
      return '<a href="' + url + '">' + url + '</a>'
    })
  }

  componentDidUpdate(prevProps, prevState) {
    let task = this.props.task ? this.props.task : {}
    let description = ''

    try {
      if (task && this.state.task !== task) {
        // console.log('Task valid')

        // const description = this.taskDescriptionLines(task)
        description = this.extractSubstring(
          task.notes,
          '__START_VIEW_IN_IGLOO__',
          '__END_VIEW_IN_IGLOO__'
        )
        // const files = this.getFilesFunc(task)

        // task.notes = description

        this.setState({ description: description })
        this.setState({ task: task })
        return { task: task }
      } else {
        // console.log('No valid task - error 1')
        return null
      }
    } catch (error) {
      console.log('No valid task - error 2')
      return null
    }
  }

  async saveToAsana() {
    try {
      this.setState({ loading: true })

      const task = this.state.task
      const prevFullNotes = task.notes
      const prevInnerNotes = this.extractSubstring(
        task.notes,
        '__START_VIEW_IN_IGLOO__',
        '__END_VIEW_IN_IGLOO__'
      )
      let latestDescription = ''

      latestDescription = window.document.querySelector(
        `#t-${task.gid} .task-description`
      ).value

      latestDescription = latestDescription.trim()
      latestDescription = `${latestDescription} \n`

      let mergedNotes = prevFullNotes.replace(prevInnerNotes, latestDescription)
      const mergedNotesInner = this.extractSubstring(
        mergedNotes,
        '__START_VIEW_IN_IGLOO__',
        '__END_VIEW_IN_IGLOO__'
      )

      this.setState({ description: mergedNotesInner })
      this.setState({ task: { ...this.state.task, notes: mergedNotes } })

      return axios({
        method: 'PUT',
        url: `https://app.asana.com/api/1.0/tasks/${task.gid}`,
        headers: {
          Authorization: `Bearer 0/4089f7d1f889af6814aabe2213be272c`,
          'Content-Type': 'application/json'
        },
        data: {
          data: {
            notes: mergedNotes
          }
        }
      }).then(x => {
        alert('Save was successful.')
        this.setState({ loading: false })
      })
    } catch (error) {
      console.log(error)
      console.log(`#t-${this.state.task.gid} .task-description`)
      console.log(util.inspect(this.state))
      console.log(util.inspect(this.props))
      this.setState({ loading: false })
      alert('Unable to save.')
    }
  }

  render() {
    const { description } = this.state

    return (
      <div>
        {this.state.loading && (
          <div id="loader" className="ontop-loader">
            <CircularProgress />
          </div>
        )}

        <div className="fullwidth">
          <TextArea
            rows={20}
            className="fullwidth task-description"
            defaultValue={this.state.description}
          />
        </div>
        <Divider />

        <Row className="aligncenter" gutter={16}>
          <Col className="gutter-row" span={8}>
            <div className="gutter-box" />
          </Col>
          <Col className="gutter-row" span={8}>
            <Button
              className="fullwidth"
              type="primary"
              onClick={() => this.saveToAsana()}
            >
              Save
            </Button>
          </Col>
          <Col className="gutter-row" span={8}>
            <div className="gutter-box" />
          </Col>
        </Row>
      </div>
    )
  }
}

export default Edit
