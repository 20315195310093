import React, { Component } from 'react'
import Intercom from 'react-intercom'
import util from 'util'

export class IntercomLoad extends Component {
  constructor(props) {
    super(props)
  }

  state = {
    loaded: false
  }

  componentDidMount() {
    // console.log('IntercomLoad')
    // console.log(util.inspect(this.props))
    let user = {}

    if (this.props.id) {
      this.setState({
        loaded: true,
        user: {
          user_id: this.props.id,
          email: this.props.email,
          name: this.props.name
        }
      })
    } else {
      this.setState({ loaded: false, user: {} })
    }
  }

  render() {
    const { user } = this.state

    return (
      <div>{this.state.user && <Intercom appID="izd6jjnc" {...user} />}</div>
    )
  }
}

export default IntercomLoad
