import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { Facebook } from "react-content-loader"

import { NavLink } from "react-router-dom"

// Material-UI
import AppBar from "@material-ui/core/AppBar"
import Drawer from "@material-ui/core/Drawer"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import { fade } from "@material-ui/core/styles/colorManipulator"
import { withStyles } from "@material-ui/core/styles"
import MenuIcon from "@material-ui/icons/Menu"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Hidden from "@material-ui/core/Hidden"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import Collapse from "@material-ui/core/Collapse"

import UserInfo from "./UserInfo"
import Notifications from "./Notifications"
import SideBarCat from "./SideBarCat"

import Collapsible from "react-collapsible"

// import AppLogo from '../../assests/images/logo-beta.svg'
import AppLogo from "../../assests/images/logos/no-text/1.png"

import util from "util"

const drawerWidth = 250
let igloo_user
let env = "prod"

const styles = theme => ({
  root: {
    width: "100%"
  },
  grow: {
    flexGrow: 1
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    border: 0
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto"
    }
  },
  searchIcon: {
    width: theme.spacing(9),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit",
    width: "100%"
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing(10),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200
    }
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex"
    }
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  }
})

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.handleSideCatLinkClick = this.handleSideCatLinkClick.bind(this)
    this.handleTaskSubmitError = this.handleTaskSubmitError.bind(this)
  }

  state = {
    grayEleClassName: null,
    anchorEl: null,
    mobileMoreAnchorEl: null,
    mobileOpen: false,
    automateOpen: false,
    automateOpenFE: false,
    automateOpenBE: false,
    expanded: false,
    sideBarCatsDesign: [],
    sideBarCatsFE: [],
    sideBarCatsBE: [],
    user: null,
    trialDaysLeft: "",
    isTrial: false,
    sideCatLinkClicked: false
  }

  handleSideCatLinkClick(data) {
    // console.log('handleSideCatLinkClick(link)')
    // console.log( util.inspect(e.currentTarget.className, {depth: 3}) )

    if (this.state.user) {
      this.handleTaskSubmitError({
        event: data.event,
        isTrial: this.state.isTrial,
        plan: this.state.user.plan.toLowerCase(),
        formid: data.formid
      })
    }

    this.setState({
      sideCatLinkClicked: true
    })
  }

  handleTaskSubmitError(e) {
    // console.log('handleTaskSubmitError(e)')
    // console.log( util.inspect(e.currentTarget.className, {depth: 3}) )

    this.props.onHandleShowTaskSubmitError(e)
  }

  expandChange = () => {
    this.setState({
      expanded: !this.state.expanded
    })
  }

  automateClick = () => {
    this.setState(state => ({ automateOpen: !state.automateOpen }))
  }

  automateClickFE = () => {
    this.setState(state => ({ automateOpenFE: !state.automateOpenFE }))
  }

  automateClickBE = () => {
    this.setState(state => ({ automateOpenBE: !state.automateOpenBE }))
  }

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }))
  }

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleMenuClose = () => {
    this.setState({ anchorEl: null })
    this.handleMobileMenuClose()
  }

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget })
  }

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null })
  }

  getTypeformsHelper(plan) {
    plan = plan.toLowerCase()

    if (plan.includes("closed") || plan.includes("Closed")) {
      // don't get the typeforms
      alert("Account closed. Please contact support if you wish to upgrade.")
    } else if (
      plan.includes("core") ||
      plan.includes("trial") ||
      plan.includes("trialing")
    ) {
      this.getTypeformsDesign()
      this.getTypeformsFE()
      this.getTypeformsBE()
      this.setState({ grayEleClassName: "grayed-out" })
    } else if (plan.includes("plus") || plan.includes("enterprise")) {
      this.getTypeformsDesign()
      this.getTypeformsFE()
      this.getTypeformsBE()
    } else {
      // error with users plan name
      this.getTypeformsDesign()
      this.getTypeformsFE()
      this.getTypeformsBE()
    }
  }

  async componentWillMount() {
    // await this.updateAccount()
    igloo_user = JSON.parse(localStorage.getItem("igloo_user"))
    this.setState({ user: igloo_user })
    this.isTrial(igloo_user.id)

    this.getTypeformsHelper(igloo_user.plan)
  }

  async componentDidMount() {
    let igloo_user_data = JSON.parse(localStorage.getItem("igloo_user"))

    this.isTrial(igloo_user_data.id)
  }

  async isTrial(userid) {
    if (userid) {
      // await fetch(`https://get-is-trial-igloo-api-v2.now.sh/?userid=${userid}`)
      //   .then(type => type.json())
      //   .then(response => {
      //     env = 'prod'
      //     if (response.istrialRaw) {
      //       // console.log('setting state isTrial to true')
      //       // console.log('setting state trialDaysLeft to')
      //       // console.log(util.inspect(response))
      //       this.setState({
      //         isTrial: true,
      //         trialDaysLeft: response.daysLeftRaw
      //       })
      //       let tempUser = JSON.parse(localStorage.getItem('igloo_user'))
      //       tempUser.trial_days = response.daysLeftRaw
      //       localStorage.setItem('igloo_user', JSON.stringify(tempUser))
      //     } else {
      //       // this.setState({
      //       //   isTrial: false, trialDaysLeft: ''
      //       // })
      //     }
      //     // console.log(`isTrial(${userid}) => response`)
      //     // console.log(response)
      //   })
      //   .catch(async errTrial => {
      //     await fetch(
      //       `https://get-is-trial-igloo-api-v2.now.sh/?userid=${userid}`
      //     )
      //       .then(type => type.json())
      //       .then(response => {
      //         if (response.istrialRaw) {
      //           // console.log('setting state isTrial to true')
      //           // console.log('setting state trialDaysLeft to')
      //           // console.log(util.inspect(response))
      //           this.setState({
      //             isTrial: true,
      //             trialDaysLeft: response.daysLeftRaw
      //           })
      //         } else {
      //           // this.setState({
      //           //   isTrial: false, trialDaysLeft: ''
      //           // })
      //         }
      //         // console.log(`isTrial(${userid}) => response`)
      //         // console.log(response)
      //       })
      //   })
    }
  }

  getTypeformsDesign() {
    fetch(
      `https://us-central1-igloo-8c65d.cloudfunctions.net/tests/get-typeform-teams/design`
    )
      .then(resFormat => resFormat.json())
      .then(typeforms => {
        // console.log(typeforms)

        let sideBarCatsDesign = typeforms.items.map(x => {
          return { form_id: x.id, user_id: igloo_user.id, form_title: x.title }
        })

        this.setState({ sideBarCatsDesign })
      })
  }

  getTypeformsFE() {
    fetch(
      `https://us-central1-igloo-8c65d.cloudfunctions.net/tests/get-typeform-teams/frontend`
    )
      .then(resFormat => resFormat.json())
      .then(typeforms => {
        // console.log(typeforms)

        let sideBarCatsFE = typeforms.items.map(x => {
          return { form_id: x.id, user_id: igloo_user.id, form_title: x.title }
        })

        this.setState({ sideBarCatsFE })
      })
  }

  getTypeformsBE() {
    fetch(
      `https://us-central1-igloo-8c65d.cloudfunctions.net/tests/get-typeform-teams/backend`
    )
      .then(resFormat => resFormat.json())
      .then(typeforms => {
        // console.log(typeforms)

        let sideBarCatsBE = typeforms.items.map(x => {
          return { form_id: x.id, user_id: igloo_user.id, form_title: x.title }
        })

        this.setState({ sideBarCatsBE })
      })
  }

  render() {
    const { anchorEl, mobileMoreAnchorEl, isTrial, trialDaysLeft } = this.state
    const { classes, theme } = this.props
    const isMenuOpen = Boolean(anchorEl)
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)
    let trial = this.state.isTrial
    let plan = this.state.user.plan
    const showUpgrade = plan.toLowerCase() === "enterprise" ? false : true

    let sideBarCatsOutDesign = this.state.sideBarCatsDesign.map(x => {
      return (
        <SideBarCat
          isTrial={trial}
          plan={plan}
          className="cat-design-item"
          onHandleSideCatLinkClick={this.handleSideCatLinkClick}
          formid={x.form_id}
          userid={x.user_id}
          key={x.form_id}
          title={x.form_title}
        />
      )
    })

    let sideBarCatsOutFE = this.state.sideBarCatsFE.map(x => {
      return (
        <SideBarCat
          isTrial={trial}
          plan={plan}
          className="cat-fe-item"
          onHandleSideCatLinkClick={this.handleSideCatLinkClick}
          formid={x.form_id}
          key={x.form_id}
          userid={x.user_id}
          title={x.form_title}
        />
      )
    })

    let sideBarCatsOutBE = this.state.sideBarCatsBE.map(x => {
      return (
        <SideBarCat
          isTrial={trial}
          plan={plan}
          className="cat-be-item"
          onHandleSideCatLinkClick={this.handleSideCatLinkClick}
          formid={x.form_id}
          userid={x.user_id}
          key={x.form_id}
          title={x.form_title}
        />
      )
    })

    // console.log(this.state.user.plan)

    const drawer = (
      <div className="sideBar">
        {/* <ErrorDialog /> */}

        <div className={classes.toolbar}>
          <div className="img-wrap">
            <img src={AppLogo} alt="App logo" className="header-app-logo" />
          </div>
        </div>

        <List
          className={classNames("list-wrapper", {
            expanded: this.state.expanded
          })}
        >
          <ListItem className="list-wrapper-title" button>
            <NavLink to="/">
              {/* <i className="material-icons">
                arrow_right
              </i> */}
              <ListItemText className="list-text" primary="Tasks" />
            </NavLink>
          </ListItem>

          <ListItem className="list-wrapper-title" button>
            <NavLink
              className="support-btn active"
              onClick={this.forceUpdate}
              to={{ pathname: "/create/task", propsCurrent: "0" }}
            >
              {/* <NavLink className="support-btn active" to="/"> */}
              {/* <i className="material-icons">
                arrow_right
              </i> */}
              <ListItemText className="list-text" primary="Create a task" />
            </NavLink>
          </ListItem>

          <ListItem
            id="sidebar-cat-design"
            className="drop-down "
            button
            // onClick={this.automateClick}
          >
            <NavLink
              onClick={this.forceUpdate}
              to={{ pathname: "/create/task", propsCurrent: "0" }}
            >
              <i className="material-icons">arrow_right</i>
              <ListItemText
                className="list-text"
                primary="Digital & Graphic Design"
              />
            </NavLink>

            {this.state.automateOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          {/* 
          <Collapse
            id="sidebar-cat-design-nested"
            in={this.state.automateOpen}
            timeout="auto"
            unmountOnExit
          >
            <List
              className="second-list drop-down"
              component="div"
              disablePadding
            >
              {sideBarCatsOutDesign}
            </List>
          </Collapse> */}

          <ListItem
            id="sidebar-cat-fe"
            className={"drop-down " + this.state.grayEleClassName}
            button
            // onClick={this.automateClickFE}
          >
            <NavLink
              onClick={this.forceUpdate}
              to={{ pathname: "/create/task", propsCurrent: "0" }}
            >
              <i className="material-icons">arrow_right</i>
              <ListItemText className="list-text" primary="Web Design" />
            </NavLink>

            {this.state.automateOpenFE ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          {/* <Collapse
            id="sidebar-cat-fe-nested"
            className={this.state.grayEleClassName}
            in={this.state.automateOpenFE}
            timeout="auto"
            unmountOnExit
          >
            <List
              className="second-list drop-down"
              component="div"
              disablePadding
            >
              {sideBarCatsOutFE}
            </List>
          </Collapse> */}

          {/* <ListItem
            id="sidebar-cat-be"
            className={"drop-down " + this.state.grayEleClassName}
            button
            onClick={this.automateClickBE}
          >
            <NavLink to="#">
              <i className="material-icons">arrow_right</i>
              <ListItemText className="list-text" primary="Development" />
            </NavLink>

            {this.state.automateOpenBE ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse
            id="sidebar-cat-be-nested"
            className={this.state.grayEleClassName}
            in={this.state.automateOpenBE}
            timeout="auto"
            unmountOnExit
          >
            <List
              className="second-list drop-down"
              component="div"
              disablePadding
            >
              {sideBarCatsOutBE}
            </List>
          </Collapse> */}

          <ListItem className="support-btn list-wrapper-title hideme" button>
            <NavLink className="support-btn active" to="/support">
              {/* <i className="material-icons">
                arrow_right
              </i> */}
              <ListItemText className="list-text" primary="Support" />
            </NavLink>
          </ListItem>

          <ListItem className="support-btn list-wrapper-title hideme" button>
            <NavLink className="support-btn active" to="/chat">
              {/* <i className="material-icons">
                arrow_right
              </i> */}
              <ListItemText className="list-text" primary="Task Discussions" />
            </NavLink>
          </ListItem>

          <ListItem className="list-wrapper-title" button>
            <NavLink className="active" to="/billing">
              {/* <i className="material-icons">
                arrow_right
              </i> */}
              <ListItemText className="list-text" primary="Billing" />
            </NavLink>
          </ListItem>
        </List>

        {showUpgrade && (
          <div className={classNames("upgrade-wrap", "support-upgrade-btn")}>
            <div className="section-title">enterprise</div>
            <div className="text-content">
              Upgrade your plan now to enjoy enterprise features
            </div>
            <div className="custom-button gradient hero-button support-upgrade-btn">
              Go enterprise
            </div>
          </div>
        )}
      </div>
    )

    return (
      <div className={classes.root}>
        <AppBar position="fixed" className={`${classes.appBar} app-header`}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <div className={classes.grow} />
            <div className="menu">
              <ul className="menu-list">
                <li>{/* <Notifications /> */}</li>
                <li>
                  {/* <UserInfo isTrial={this.state.isTrial} trialDaysLeft={this.state.trialDaysLeft}/> */}
                  <UserInfo
                    isTrial={this.state.isTrial}
                    trialDaysLeft={this.state.trialDaysLeft}
                  />
                </li>
              </ul>
            </div>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer}>
          <Hidden smUp implementation="css">
            <Drawer
              container={this.props.container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper
              }}
              ModalProps={{
                keepMounted: true // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden smDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
      </div>
    )
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  container: PropTypes.object,
  theme: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(Header)
