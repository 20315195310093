import React, { Component } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Popover from '@material-ui/core/Popover'
import IdForm from './IdForm'
import Profile from '../../../assests/images/portrait_placeholder.png'
import util from 'util'
import firebase from '../../firebase'

class UserInfo extends Component {
  state = {
    user: {},
    anchorEl: null,
    name: '',
    email: '',
    plan: '',
    trialDaysLeft: null,
    isTrial: false
  }

  async componentWillMount() {
    const self = this
    // await this.updateAccount()
    self.setState({
      trialDaysLeft: this.props.trialDaysLeft,
      isTrial: this.props.isTrial
    })
  }

  async componentDidMount() {
    const igloo_user = JSON.parse(localStorage.getItem('igloo_user'))

    // console.log('incoming props')
    // console.log(this.props)

    this.setState({
      user: igloo_user
    })
  }

  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget
    })
  }

  handleClose = () => {
    this.setState({
      anchorEl: null
    })
  }

  logout = async () => {
    console.log('LogOut')
    // window.Intercom('shutdown')
    await firebase.logout()
    window.location.replace('/')
  }

  componentWillReceiveProps(nextProps) {
    // console.log('nextProp trialDaysLeft')
    // console.log(nextProps.trialDaysLeft)

    if (nextProps.isTrial !== this.props.isTrial) {
      this.setState({ isTrial: nextProps.isTrial })
    }

    if (nextProps.trialDaysLeft !== this.props.trialDaysLeft) {
      this.setState({ trialDaysLeft: nextProps.trialDaysLeft })
    }
  }

  cancelBtnOnClickHandler(e) {
    window.document.getElementById('barecancel-trigger').click()
  }

  upgradeBtnOnClickHandler(e) {
    // window.document.getElementById('barecancel-trigger').click()
  }

  render() {
    let { anchorEl, trialDaysLeft, isTrial } = this.state
    let { name, email, id, plan, status } = this.state.user
    const open = Boolean(anchorEl)

    const showCancelButton = () => {
      if (plan === 'core' || plan === 'Core') {
        if (isTrial) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }

    const showUpgradeButton = () => {
      if (
        plan === 'core' ||
        plan === 'Core' ||
        plan === 'plus' ||
        plan === 'Plus'
      ) {
        return true
      } else {
        return false
      }
    }

    return (
      <div className="user-info">
        <div className="user-detail">
          <div
            className="text-wrap"
            id="logout-header"
            onClick={() => this.logout()}
          >
            Log Out
          </div>
          <div
            aria-owns={open ? 'user-menu' : undefined}
            aria-haspopup="true"
            variant="contained"
            onClick={this.handleClick}
          >
            <Avatar alt="Profile" src={Profile} className="avatar" />
          </div>
          <Popover
            id="user-menu"
            className="popper"
            open={open}
            anchorEl={anchorEl}
            onClose={this.handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <div className="user-wrapper">
              <div className="head-part">
                <div className="subtitle">My Profile</div>
                <div className="logout" id="logout-header2">
                  <i className="material-icons">exit_to_app</i>
                  LogOut
                </div>
              </div>
              <div className="body-part">
                {/*<div className="img-wrap">
									<img src={Profile} alt="Profile"/>
								</div >*/}
                {/*<IdForm name={name} email={email} />*/}
                <IdForm
                  name={this.state.user.name}
                  email={this.state.user.email}
                  plan={this.state.user.plan}
                  isTrial={this.state.isTrial}
                  trialDaysLeft={this.state.trialDaysLeft}
                />

                {showCancelButton() && (
                  <div
                    className="custom-button support-cancel-btn"
                    onClick={() => this.cancelBtnOnClickHandler()}
                  >
                    Cancel Trial
                  </div>
                )}

                {/* showUpgradeButton &&
									<div className="custom-button upgrade-btn" onClick={ () => this.upgradeBtnOnClickHandler() } >
										Upgrade Plan
									</div>
								*/}

                <div className="text-info">
                  {/* Your email is not verified.
									<a href="/">
										Send Verification Link
									</a> */}
                </div>
              </div>
            </div>
          </Popover>
        </div>
      </div>
    )
  }
}

export default UserInfo
