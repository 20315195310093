import React, { Component } from "react";
import {
  NavLink
} from "react-router-dom";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

class SideBarCat extends Component {
	constructor(props) {
		super(props)

		this.handleLinkClick = this.handleLinkClick.bind(this)
	}

	state = {
    form_id: "",
    user_id: "",
		title: ""
  };

	componentDidMount(){
		this.setState({
			form_id: this.props.formid,
			user_id: this.props.userid,
			title: this.props.title
		})
	}
	
	handleLinkClick(e) {
		if(this.props.isTrial || this.props.plan.toLowerCase() === 'core'){
			if(this.props.className.includes('cat-design-item')){
				this.props.onHandleSideCatLinkClick({event: e, formid: this.state.form_id});
			}else{
				this.props.onHandleSideCatLinkClick({event: e, formid: this.state.form_id});
			}
		}


		if(!this.props.isTrial || this.props.plan.toLowerCase() !== 'core'){
			this.props.onHandleSideCatLinkClick({event: e, formid: this.state.form_id});
		}
	}

	render() {

		return (
			<div>
				<NavLink to='#' className="sidebar-task-item">
					<ListItem button>
						{/* <ListItemText onClick={() => this.runTypeform(`https://pebbled.typeform.com/to/${this.state.form_id}?id=${this.state.user_id}`)} id={this.state.form_id} className="list-text" primary={this.state.title} /> */}
						{/* <ListItemText onClick={() => this.openInNewTab(`https://pebbled.typeform.com/to/${this.state.form_id}?id=${this.state.user_id}`)} id={this.state.form_id} className="list-text" primary={this.state.title} /> */}
						<ListItemText onClick={this.handleLinkClick} id={this.state.form_id} className={"list-text " + this.props.className} primary={this.state.title} />

					</ListItem>
				</NavLink>
			</div>
		);
	}
}

export default SideBarCat;
