import React, { Component } from 'react'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import classNames from 'classnames'
import CustomButton from '../../../components/GlobalUi/CustomButton'
// import ClipboardJS from 'clipboard'
import TaskExpandTabs from './TaskExpandTabs'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  heading: {
    // fontSize: theme.typography.pxToRem(15)
  },
  secondaryHeading: {
    // fontSize: theme.typography.pxToRem(15),
    // color: theme.palette.text.secondary
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20
  },
  details: {
    alignItems: 'center'
  },
  column: {
    flexBasis: '33.33%'
  },
  helper: {
    // borderLeft: `2px solid ${theme.palette.divider}`,
    // padding: theme.spacing(1, 2)
  },
  link: {
    // color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}))

export function DetailedExpansionPanel(props) {
  const classes = useStyles()
  const { title, status, full_id, newrev, team, created_at } = props.info
  let id = props.info.full_id.toString().slice(-5)
  let _url = window.location.href
  //   console.log(props.info.raw)

  const handleTaskClick = (idIn, full_id) => {
    // const URL = `http://localhost:300/dash/hello`
    // this.props.history.push({ pathname: 'https://localhost:3000/' })
    // window.history.replaceState(null, null, `/task/${full_id}`)
    // _url = window.location.href
    // var newurl =
    //   window.location.protocol +
    //   '//' +
    //   window.location.host +
    //   window.location.pathname +
    //   idIn
    // window.history.pushState({ path: newurl }, '', newurl)
  }

  const raw = JSON.parse(props.info.raw)

  return (
    // <div className={classes.root}>
    <div
      onClick={() => handleTaskClick(String(id), String(full_id))}
      id={`t-${full_id}`}
      className={classNames(
        'task-progress',
        'box-wrap',
        'with-shadow',
        { 'in-progress': status.toLowerCase().includes('in progress') },
        { feedback: status === 'Feedback' },
        { feedback: status.toLowerCase().includes('ready to review') },
        { issue: status.toLowerCase().includes('info needed') },
        { issue: status.toLowerCase().includes('issue') },
        { completed: status.toLowerCase().includes('completed') },
        { queued: status.toLowerCase().includes('queued') }
      )}
    >
      {/* <div
        id={full_id}
        className={classNames(
        'task-progress',
        'box-wrap',
        'with-shadow',
        { 'in-progress': status.toLowerCase().includes('in progress') },
        { feedback: status === 'Feedback' },
        { feedback: status.toLowerCase().includes('ready to review') },
        { issue: status.toLowerCase().includes('info needed') },
        { issue: status.toLowerCase().includes('issue') },
        { completed: status.toLowerCase().includes('completed') },
        { queued: status.toLowerCase().includes('queued') }
        )}
    >
        <div className={classNames('title-text', 'copy')}>{title}</div>

        <div className="extra-info">
        <div className={classNames('id', 'tp-field', 'created-date')}>
            {created_at}
        </div>

        <div className={classNames('id', 'tp-field')}>{newrev}</div>

        <div className={classNames('id', 'tp-field')}>{team}</div>

        <div className={classNames('id', 'copy', 'tp-field')}>{id}</div>

        <CustomButton title={status} />
        </div>
    </div> */}

      <ExpansionPanel className="fullwidth norboder noboxshadow">
        <ExpansionPanelSummary
          //   expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
          className="nomarg nominheight nopadright nopadleft"
        >
          <div className="fullwidthfix nopadright nopadleft">
            <div className={classNames('title-text', 'copy')}>{title}</div>

            <div className="extra-info">
              <div className={classNames('id', 'tp-field', 'created-date')}>
                {created_at}
              </div>

              <div className={classNames('id', 'tp-field')}>{newrev}</div>

              <div className={classNames('id', 'tp-field')}>{team}</div>

              <div className={classNames('id', 'copy', 'tp-field')}>{id}</div>

              <CustomButton title={status} />
            </div>
          </div>

          {/* <div className={classes.column}>
            <Typography className={classes.heading}>
            {props.info.title}
            </Typography>
        </div>
        <div className={classes.column}>
            <Typography className={classes.secondaryHeading}>
            Select trip destination
            </Typography>
        </div> */}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          {/* <div className={classes.column} /> */}
          <div />
          {/* <textarea className="alignleft" value={raw.notes} /> */}
          <TaskExpandTabs
            title={title}
            id={id}
            status={status}
            full_id={full_id}
            newrev={newrev}
            team={team}
            created_at={created_at}
            urlin={_url}
            task={raw}
          />

          {/*
        <div className={classes.column}>
            <Chip label="Barbados" onDelete={() => {}} />
        </div>
          
        <div className={clsx(classes.column, classes.helper)}>
            <Typography variant="caption">
            Select your destination of choice
            <br />
            <a href="#sub-labels-and-columns" className={classes.link}>
                Learn more
            </a>
            </Typography>
        </div> */}
        </ExpansionPanelDetails>
        <Divider />
        {/* <ExpansionPanelActions>
          <Button size="small">Cancel</Button>
          <Button size="small" color="primary">
            Save
          </Button>
        </ExpansionPanelActions> */}
      </ExpansionPanel>
    </div>
  )
}

class DetailedExpansionPanel2 extends Component {
  constructor() {
    super()
    this.state = { title: 'Click text' }
  }

  state = {
    title: '',
    id: '',
    status: '',
    full_id: '',
    newrev: '',
    team: '',
    created_at: ''
  }

  componentDidMount() {
    this.setState({ title: this.props.info.title })
    this.setState({ id: this.props.info.id })
    this.setState({ status: this.props.info.status })
    this.setState({ full_id: this.props.info.full_id })
    this.setState({ newrev: this.props.info.newrev })
    this.setState({ team: this.props.info.team })
    this.setState({ created_at: this.props.info.created_at })
  }

  render() {
    // let clipboard = new ClipboardJS(`.task-progress .copy`)
    const { title, id, status, full_id, newrev, team } = this.props.info

    return (
      <div>
        <DetailedExpansionPanel {...this.props} />
      </div>
    )
  }
}

export default DetailedExpansionPanel2
