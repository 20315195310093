import React, { Component } from "react";
import util from 'util'
import Plyr from 'react-plyr';

class VideoComp extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div>
                <Plyr
                type="video" // or "vimeo"
                url="https://wow.pebbled.io/_pebbled/igloo/walkthrough/igloo_walkthrough.mp4"
                />
                <a href="#" onClick={this.props.onVideoClose} class="close-thik"></a>
            </div>
        )
    }
}

export default VideoComp;