import React, { useState, useEffect } from 'react'
import './styles.css'
import HomePage from '../HomePage'
import MainApp from '../../MainApp'
import Login from '../Login'
import Login2 from '../Login2'
import Register from '../Register'
import Dashboard from '../Dashboard'
import Logout from '../Logout'
import Billing from '../../pages/Billing'
import CreateTask from '../../pages/CreateTask'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { CssBaseline, CircularProgress } from '@material-ui/core'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import firebase from '../firebase'

const theme = createMuiTheme()

const NoMatchPage = () => {
  window.location.href = '/'
}

export default function App() {
  const [firebaseInitialized, setFirebaseInitialized] = useState(false)

  useEffect(() => {
    try {
      firebase.isInitialized().then(val => {
        setFirebaseInitialized(val)
      })
    } catch (error) {
      setFirebaseInitialized(false)
    }
  })

  return firebaseInitialized !== false ? (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {/* <MainApp /> */}
      <Router>
        <Switch>
          <Route exact path="/" component={Dashboard} />

          <Route exact path="/login" component={Login} />
          <Route exact path="/super" component={Login2} />
          <Route exact path="/logout" component={Logout} />
          <Route
            path="/create/task"
            render={props => (
              <div>
                <MainApp />
                {/* <CreateTask /> */}
              </div>
            )}
          />

          <Route
            path="/billing"
            render={props => (
              <div>
                <MainApp />
                <Billing />
              </div>
            )}
          />

          <Route component={NoMatchPage} />
        </Switch>
      </Router>
    </MuiThemeProvider>
  ) : (
    <div id="loader">
      <CircularProgress />
    </div>
  )
}
