import React, { Component } from 'react'
import classNames from 'classnames'
import CustomButton from '../../../components/GlobalUi/CustomButton'
// import ClipboardJS from 'clipboard'
import TaskExpand from './TaskExpand'

class TaskProgress extends Component {
  constructor() {
    super()
    this.state = { title: 'Click text' }
  }

  state = {
    title: '',
    id: '',
    status: '',
    full_id: '',
    newrev: '',
    team: '',
    created_at: ''
  }

  componentDidMount() {
    this.setState({ title: this.props.info.title })
    this.setState({ id: this.props.info.id })
    this.setState({ status: this.props.info.status })
    this.setState({ full_id: this.props.info.full_id })
    this.setState({ newrev: this.props.info.newrev })
    this.setState({ team: this.props.info.team })
    this.setState({ created_at: this.props.info.created_at })
  }

  copyText(orig_title, copy_text, state_name) {
    this.setState({ [state_name]: copy_text })

    setTimeout(() => {
      this.setState({ [state_name]: orig_title })
    }, 1000)
  }

  render() {
    // let clipboard = new ClipboardJS(`.task-progress .copy`)
    const { title, id, status, full_id, newrev, team } = this.props.info

    return (
      <div>
        <TaskExpand {...this.props} />
      </div>

      //   <div
      //     id={full_id}
      //     className={classNames(
      //       'task-progress',
      //       'box-wrap',
      //       'with-shadow',
      //       { 'in-progress': status.toLowerCase().includes('in progress') },
      //       { feedback: status === 'Feedback' },
      //       { feedback: status.toLowerCase().includes('ready to review') },
      //       { issue: status.toLowerCase().includes('info needed') },
      //       { issue: status.toLowerCase().includes('issue') },
      //       { completed: status.toLowerCase().includes('completed') },
      //       { queued: status.toLowerCase().includes('queued') }
      //     )}
      //   >

      //     <div
      //       onClick={() => this.copyText(title, 'Copied!', 'title')}
      //       className={classNames('title-text', 'copy')}
      //       data-clipboard-text={title}
      //     >
      //       {this.state.title}
      //     </div>

      //     <div className="extra-info">
      //       <div className={classNames('id', 'tp-field', 'created-date')}>
      //         {this.state.created_at}
      //       </div>

      //       <div className={classNames('id', 'tp-field')}>
      //         {this.state.newrev}
      //       </div>

      //       <div className={classNames('id', 'tp-field')}>{this.state.team}</div>

      //       <div
      //         onClick={() => this.copyText(id, 'Copied!', 'id')}
      //         data-clipboard-text={full_id}
      //         className={classNames('id', 'copy', 'tp-field')}
      //       >
      //         {this.state.id}
      //       </div>

      //       <CustomButton
      //         onClick={() => this.copyText(status, 'Copied!', 'status')}
      //         data-clipboard-text={status}
      //         className="copy"
      //         title={this.state.status}
      //       />
      //     </div>
      //   </div>
    )
  }
}

export default TaskProgress
