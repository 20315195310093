import React, { Component } from 'react'
import { Timeline, TimelineEvent } from 'react-event-timeline'
// import { TimelineEvent } from 'react-event-timeline'
// import { Timeline } from 'antd'

import TaskComments from './TaskComments'
import axios from 'axios'
import util from 'util'

class Timeliner extends Component {
  constructor(props) {
    super(props)
  }

  state = {
    events: [],
    task: {},
    user: {}
  }

  getEvents(task) {
    return axios(`https://app.asana.com/api/1.0/tasks/${task.gid}/stories`, {
      method: 'GET',
      headers: { Authorization: 'Bearer 0/4089f7d1f889af6814aabe2213be272c' }
    })
      .then(x => {
        const r = x.data.data

        this.setState({ events: r })

        // console.log('all task events in for timeline')
        // console.log(util.inspect(r))
      })
      .catch(y => {
        this.setState({ events: [] })
      })
  }

  componentDidMount() {
    // this.removeCommentBox = commentBox(`5632418417475584-proj`)
    const task = this.props.task ? this.props.task : { gid: null }

    const igloo_user = JSON.parse(localStorage.getItem('igloo_user'))
    this.setState({ task: this.props.task, user: igloo_user })

    this.getEvents(task)
  }

  componentWillUnmount() {
    // this.removeCommentBox()
  }

  getFileFunc(attachment_gid) {
    console.log(`https://app.asana.com/api/1.0/attachments/${attachment_gid}`)

    return axios({
      method: 'GET',
      url: `https://app.asana.com/api/1.0/attachments/${attachment_gid}`,
      headers: {
        Authorization: `Bearer 0/4089f7d1f889af6814aabe2213be272c`,
        'Content-Type': 'application/json'
      }
    }).then(resOut => resOut.data.data)
  }

  handleRefreshFunc() {
    const self = this

    const task = this.props.task ? this.props.task : { gid: null }
    this.getEvents(task)

    console.log('FORCING RERENDER')
    setTimeout(() => {
      self.forceUpdate()
    }, 4000)
  }

  prettyDate(date) {
    return new Date(date).toString()
  }

  render() {
    const { events, user, task } = this.state
    let assignedName = null

    try {
      assignedName = task.assignee.name
    } catch (asErr) {
      assignedName = null
    }

    return (
      <div>
        <Timeline>
          {this.state.events &&
            events.map(e => {
              const createdAtPretty = this.prettyDate(e.created_at)

              if (e.resource_subtype === 'comment_added') {
                // console.log(`ASSIGNED NAME = ${assignedName}`)

                let text = e.text ? e.text : null
                let replaceMe = null
                let atToClient = false
                const split = text.split('\n')

                for (let sp of split) {
                  if (sp.includes('asana')) {
                    replaceMe = sp
                  }
                }

                if (text.includes('asana.com/0/1110295116043790')) {
                  atToClient = true
                } else {
                  atToClient = false
                }

                text = text.replace(replaceMe, '').trim()
                text = text
                  .replace('https://app.asana.com/0/1110295116043790/list', '')
                  .trim()

                // console.log(`!!!!!!!!!!!!!!1 atToClient @@ - ${atToClient}`)
                // console.log(text)

                if (atToClient || e.created_by.name === 'Asana Bot') {
                  return (
                    <TimelineEvent
                      title={
                        e.created_by && e.created_by.name === 'Asana Bot'
                          ? user.name
                          : 'Design Team'
                      }
                      // subtitle={
                      //   e.resource_subtype ? e.resource_subtype : 'No type'
                      // }
                      createdAt={createdAtPretty}
                      icon={
                        <i className="material-icons md-18">
                          {e.created_by && e.created_by.name === 'Asana Bot'
                            ? 'verified_user'
                            : 'feedback'}
                        </i>
                      }
                    >
                      <pre>{text ? text : `ERR - ${e.text}`}</pre>
                      {/* <pre>{e.text ? e.text : `ERR`}</pre> */}
                    </TimelineEvent>
                  )
                } else {
                  return []
                }
              } else if (e.resource_subtype === 'attachment_added') {
                let text = e.text ? e.text : null
                const split = text.split('asset_id=')
                const fileId = split[1]

                const split2 = text.split('\n')
                let replaceMe = null

                for (let sp of split2) {
                  if (sp.includes('asana')) {
                    replaceMe = sp
                  }
                }

                text = text.replace(replaceMe, '').trim()

                let fileD = false

                if (!this.state[fileId]) {
                  fileD = this.getFileFunc(fileId).then(fileX => {
                    this.setState({ [fileId]: fileX.view_url })
                  })
                }

                return (
                  <TimelineEvent
                    title={
                      e.created_by && e.created_by.name === 'Asana Bot'
                        ? user.name
                        : 'Design Team'
                    }
                    // subtitle={
                    //   e.resource_subtype ? e.resource_subtype : 'No type'
                    // }
                    createdAt={createdAtPretty}
                    icon={
                      <i className="material-icons md-18">
                        {e.created_by && e.created_by.name === 'Asana Bot'
                          ? 'verified_user'
                          : 'feedback'}
                      </i>
                    }
                  >
                    {text && <pre>text</pre>}
                    <img width="400px" src={this.state[fileId]} />
                  </TimelineEvent>
                )
              } else {
                return []
              }
            })}
        </Timeline>
        <TaskComments
          task={this.props.task}
          handleRefresh={() => this.handleRefreshFunc()}
        />
        {!this.state.events && <h1>Loading....</h1>}
      </div>
    )
  }
}

export default Timeliner
