import React, { Component } from 'react';

class TaskInfoDialog extends Component {
    constructor(props) {
        super(props)
    }

    state = {

    }

    componentWillMount() {

    }

    componentDidMount() {

    }

    render() {

        return (
            <div>
                <svg viewBox="0 0 320 700" id="task-info-dialog" className="general-dialog">
                    <defs>
                    <style>
                        {
                        ".a{clip-path:url(#d);}.b,.f,.n,.o{fill:#fff;}.c{fill:#f2f2f2;}.d,.e,.p,.s{fill:#8583bf;}.d{font-size:17.923px;}.d,.f{font-family:AvenirLTStd-Light, Avenir LT Std;font-weight:300;}.e,.m,.r{stroke:#fff;}.e,.m,.o{stroke-linecap:round;}.e,.m,.o,.r{stroke-miterlimit:10;}.f,.n{font-size:14.936px;}.g{fill:#20aaea;}.h{opacity:0.4;}.i{fill:#f37e4f;}.j{fill:#ffc655;}.k{fill:#f34f98;}.l{fill:#4fe0f3;}.m,.w{fill:none;}.n,.p{font-family:SegoeUI, Segoe UI;}.o{stroke:#8583bf;}.p{font-size:9.957px;}.q{fill:#79cacd;}.r{fill:#7ccccd;}.t{fill:url(#a);}.u{opacity:0.15;}.v{fill:url(#b);}"
                        }
                    </style>
                    <linearGradient
                        id="a"
                        x1={15.789}
                        y1={-12.459}
                        x2={15.475}
                        y2={-12.1}
                        gradientUnits="objectBoundingBox"
                    >
                        <stop offset={0} stopColor="#97d9da" />
                        <stop offset={0.011} stopColor="#9adadb" />
                        <stop offset={0.131} stopColor="#bae6e7" />
                        <stop offset={0.231} stopColor="#cdedef" />
                        <stop offset={0.3} stopColor="#d5f0f2" />
                    </linearGradient>
                    <linearGradient
                        id="b"
                        x1={17.888}
                        y1={-12.408}
                        x2={18.405}
                        y2={-12.925}
                        gradientUnits="objectBoundingBox"
                    >
                        <stop offset={0} />
                        <stop offset={0.343} stopColor="#fcfcfc" stopOpacity={0.012} />
                        <stop offset={0.345} stopColor="#fff" stopOpacity={0} />
                    </linearGradient>
                    <clipPath id="d">
                        <rect width={318.626} height={696.992} />
                    </clipPath>
                    </defs>
                    <g id="c" className="a">
                    <rect className="b" width={318.626} height={696.992} />
                    <g transform="translate(-722.687 -1.504)">
                        <path
                        className="b"
                        d="M1041.313,19.924A18.421,18.421,0,0,0,1022.892,1.5H741.108a18.421,18.421,0,0,0-18.421,18.42V71.2h318.626Z"
                        />
                        <path
                        className="c"
                        d="M722.687,71.2V680.076a18.421,18.421,0,0,0,18.421,18.42h281.784a18.421,18.421,0,0,0,18.421-18.42V71.2Z"
                        />
                        <text className="d" transform="translate(881.005 34.384)">
                        <tspan x={-99.265} y={0}>
                            {"Editable title comes here"}
                        </tspan>
                        <tspan x={-103.288} y={21.507}>
                            {"- Second row just in case -"}
                        </tspan>
                        </text>
                        <rect
                        className="e"
                        width={265.205}
                        height={26.884}
                        rx={13.442}
                        transform="translate(749.397 89.624)"
                        />
                        <text className="f" transform="translate(948.427 107.675)">
                        <tspan x={-34.949} y={0}>
                            {"12.04.2019"}
                        </tspan>
                        </text>
                        <path
                        className="g"
                        d="M1001.161,136.422H881.917v26.884h119.244a13.442,13.442,0,1,0,0-26.884Z"
                        />
                        <path
                        className="e"
                        d="M762.839,136.422a13.442,13.442,0,1,0,0,26.884H881.917V136.422Z"
                        />
                        <text className="f" transform="translate(818.862 107.675)">
                        <tspan x={-44.829} y={0}>
                            {"Date Created"}
                        </tspan>
                        </text>
                        <g className="h">
                        <path
                            className="i"
                            d="M992.229,229.093a12.79,12.79,0,0,1,.237,3.813c-.286,3.4-1.6,4.41-2.351,4.711-2.012.8-4.933-.819-5.78-1.334h0c-.848-.517-3.628-2.367-3.84-4.522-.08-.809.217-2.438,3.1-4.252a12.76,12.76,0,0,1,3.5-1.541,3.6,3.6,0,0,1,5.134,3.126Zm-6.552,4.985c1.564.951,2.936,1.254,3.434,1.055.211-.084.721-.844.865-2.558a9.62,9.62,0,0,0-.17-2.83,1.381,1.381,0,0,0-2.178-1.325,9.616,9.616,0,0,0-2.592,1.147c-1.456.916-1.9,1.718-1.875,1.944.052.533.952,1.614,2.515,2.566Z"
                        />
                        <path
                            className="j"
                            d="M1001.21,674.727a13,13,0,0,1,3.742,1.014c3.173,1.382,3.718,2.971,3.761,3.795.115,2.194-2.393,4.473-3.165,5.119h0c-.773.647-3.457,2.716-5.6,2.217-.8-.187-2.272-1-3.075-4.369a12.966,12.966,0,0,1-.342-3.861,3.658,3.658,0,0,1,4.676-3.916Zm2.657,7.92c1.423-1.192,2.161-2.413,2.133-2.956-.012-.23-.577-.968-2.177-1.665a9.771,9.771,0,0,0-2.775-.758,1.4,1.4,0,0,0-1.983,1.661,9.753,9.753,0,0,0,.259,2.865c.405,1.7,1.032,2.383,1.256,2.435.53.124,1.862-.389,3.286-1.581Z"
                        />
                        <path
                            className="k"
                            d="M903.624,618.633a9.642,9.642,0,0,1,2.779.754c2.357,1.026,2.762,2.206,2.794,2.818.086,1.63-1.777,3.322-2.351,3.8h0c-.574.481-2.568,2.017-4.157,1.647-.6-.139-1.687-.745-2.284-3.245a9.642,9.642,0,0,1-.254-2.868,2.718,2.718,0,0,1,3.473-2.909Zm1.973,5.883a3.923,3.923,0,0,0,1.585-2.2c-.009-.17-.429-.719-1.617-1.236a7.245,7.245,0,0,0-2.061-.563,1.041,1.041,0,0,0-1.473,1.234,7.25,7.25,0,0,0,.192,2.127c.3,1.261.767,1.771.933,1.809a3.923,3.923,0,0,0,2.441-1.175Z"
                        />
                        <path
                            className="k"
                            d="M759.778,337.97l1.487,1.416,1.416-1.487a1.092,1.092,0,1,1,1.582,1.505l-1.415,1.488,1.487,1.415a1.092,1.092,0,0,1-1.506,1.583l-1.487-1.416-1.416,1.488a1.092,1.092,0,0,1-1.582-1.506l1.415-1.488-1.487-1.415a1.092,1.092,0,0,1,1.506-1.583Z"
                        />
                        <path
                            className="i"
                            d="M1029.914,516.348l1.487,1.416,1.416-1.488a1.092,1.092,0,0,1,1.582,1.506l-1.415,1.488,1.487,1.415a1.092,1.092,0,1,1-1.506,1.582l-1.487-1.415-1.416,1.487a1.092,1.092,0,1,1-1.582-1.506l1.416-1.487-1.488-1.415a1.092,1.092,0,0,1,1.506-1.583Z"
                        />
                        <path
                            className="i"
                            d="M760.711,77.454l1.487,1.415,1.416-1.487a1.092,1.092,0,1,1,1.582,1.506l-1.416,1.487,1.488,1.416a1.092,1.092,0,1,1-1.506,1.582l-1.487-1.416-1.416,1.488a1.092,1.092,0,0,1-1.582-1.506l1.415-1.487-1.487-1.416a1.092,1.092,0,1,1,1.506-1.582Z"
                        />
                        <path
                            className="l"
                            d="M1006.372,124.412l2.053-.051-.051-2.052a1.092,1.092,0,1,1,2.183-.054l.051,2.052,2.053-.051a1.092,1.092,0,1,1,.054,2.184l-2.053.051.051,2.053a1.092,1.092,0,0,1-2.184.054l-.05-2.053-2.053.051a1.092,1.092,0,1,1-.054-2.184Z"
                        />
                        <path
                            className="l"
                            d="M982.7,318.356a5,5,0,0,0,3.557-1.474,1.118,1.118,0,0,0-1.581-1.581,2.795,2.795,0,0,1-3.953-3.952,1.118,1.118,0,1,0-1.581-1.581,5.031,5.031,0,0,0,3.558,8.588Z"
                        />
                        <path
                            className="j"
                            d="M754.822,261.3a5,5,0,0,0-1.473-3.558,1.118,1.118,0,0,0-1.581,1.581,2.8,2.8,0,0,1-3.953,3.953,1.118,1.118,0,1,0-1.581,1.581,5.031,5.031,0,0,0,8.588-3.557Z"
                        />
                        <path
                            className="j"
                            d="M1013.568,359.589a4.794,4.794,0,1,0,0-9.589,1.066,1.066,0,1,0,0,2.131,2.663,2.663,0,1,1-2.664,2.663,1.065,1.065,0,0,0-2.131,0A4.8,4.8,0,0,0,1013.568,359.589Z"
                        />
                        <path
                            className="k"
                            d="M733.32,170.039a4.794,4.794,0,1,0,9.589,0,1.065,1.065,0,0,0-2.131,0,2.664,2.664,0,1,1-2.663-2.663,1.066,1.066,0,1,0,0-2.131A4.8,4.8,0,0,0,733.32,170.039Z"
                        />
                        <path
                            className="k"
                            d="M772.253,644.224l-.72,2.765a3.12,3.12,0,0,1-3.8,2.231l-2.765-.719a3.12,3.12,0,0,1-2.231-3.8l.719-2.765a3.121,3.121,0,0,1,3.8-2.232l2.765.72A3.121,3.121,0,0,1,772.253,644.224Zm-4,2.985a1.039,1.039,0,0,0,1.267-.743l.72-2.765a1.04,1.04,0,0,0-.744-1.267l-2.765-.72a1.041,1.041,0,0,0-1.267.744l-.72,2.765a1.041,1.041,0,0,0,.744,1.267Z"
                        />
                        </g>
                        <line
                        className="m"
                        x2={209.098}
                        transform="translate(776.455 71.203)"
                        />
                        <line className="m" y2={18.918} transform="translate(882 93.606)" />
                        <rect
                        className="e"
                        width={181.218}
                        height={26.884}
                        rx={13.442}
                        transform="translate(791.391 232.809)"
                        />
                        <text className="n" transform="translate(882 250.861)">
                        <tspan x={-41.758} y={0}>
                            {"Delivery Link"}
                        </tspan>
                        </text>
                        <rect
                        className="o"
                        width={265.205}
                        height={26.884}
                        rx={13.442}
                        transform="translate(749.397 272.964)"
                        />
                        <text className="p" transform="translate(882 290.224)">
                        <tspan x={-105.565} y={0}>
                            {"https://www.dropbox.com/login?cont=https% ..."}
                        </tspan>
                        </text>
                        <rect
                        className="e"
                        width={181.218}
                        height={26.884}
                        rx={13.442}
                        transform="translate(791.391 321.516)"
                        />
                        <text className="n" transform="translate(882 339.568)">
                        <tspan x={-19.566} y={0}>
                            {"Notes"}
                        </tspan>
                        </text>
                        <rect
                        className="o"
                        width={265.205}
                        height={250.651}
                        rx={22.739}
                        transform="translate(749.397 361.671)"
                        />
                        <text className="n" transform="translate(948.427 154.474)">
                        <tspan x={-29.689} y={0}>
                            {"Back End"}
                        </tspan>
                        </text>
                        <text className="n" transform="translate(818.862 154.474)">
                        <tspan x={-17.32} y={0}>
                            {"Team"}
                        </tspan>
                        </text>
                        <path
                        className="q"
                        d="M1001.161,183.094H881.917v26.884h119.244a13.442,13.442,0,1,0,0-26.884Z"
                        />
                        <path
                        className="e"
                        d="M762.839,183.094a13.442,13.442,0,1,0,0,26.884H881.917V183.094Z"
                        />
                        <text className="n" transform="translate(948.427 201.146)">
                        <tspan x={-15.289} y={0}>
                            {"Final"}
                        </tspan>
                        </text>
                        <text className="n" transform="translate(818.862 201.146)">
                        <tspan x={-19.982} y={0}>
                            {"Status"}
                        </tspan>
                        </text>
                        <text className="p" transform="translate(766.498 372.26)">
                        <tspan x={0} y={11}>
                            {"Lorem ipsum dolor sit amet, consectetur adipiscing "}
                        </tspan>
                        <tspan x={0} y={22.948}>
                            {"elit. Mauris scelerisque mauris eu eros hendrerit "}
                        </tspan>
                        <tspan x={0} y={34.897}>
                            {"commodo. "}
                        </tspan>
                        <tspan x={0} y={46.845} />
                        <tspan x={0} y={58.794}>
                            {"Curabitur non pretium purus, non scelerisque nunc. "}
                        </tspan>
                        <tspan x={0} y={70.742}>
                            {"Donec nec velit nec dolor congue molestie. "}
                        </tspan>
                        <tspan x={0} y={82.691}>
                            {"Curabitur molestie lectus augue, sed vestibulum "}
                        </tspan>
                        <tspan x={0} y={94.639}>
                            {"nunc rhoncus non. Pellentesque quis libero vitae "}
                        </tspan>
                        <tspan x={0} y={106.588}>
                            {"ligula luctus aliquet. "}
                        </tspan>
                        <tspan x={0} y={118.536} />
                        <tspan x={0} y={130.484}>
                            {"Fusce dictum tellus vitae elit maximus eleifend. Sed "}
                        </tspan>
                        <tspan x={0} y={142.433}>
                            {"tempus faucibus nunc ut pulvinar."}
                        </tspan>
                        <tspan x={0} y={154.381} />
                        <tspan x={0} y={166.33}>
                            {"Vestibulum imperdiet volutpat facilisis. "}
                        </tspan>
                        <tspan x={0} y={178.278} xmlSpace="preserve">
                            {"Phasellus  consectetur "}
                        </tspan>
                        <tspan x={0} y={190.227} />
                        <tspan x={0} y={202.175}>
                            {"faucibus elit at auctor. Cras bibendum rutrum jest "}
                        </tspan>
                        <tspan x={0} y={214.123}>
                            {"nec bibendum aliquam. Etiam tincidunt ut urna in "}
                        </tspan>
                        <tspan x={0} y={226.072}>
                            {"convallis. "}
                        </tspan>
                        </text>
                        <rect
                        className="r"
                        width={127.645}
                        height={20.407}
                        rx={9.829}
                        transform="translate(818.177 664.075)"
                        />
                        <rect
                        className="e"
                        width={127.645}
                        height={20.407}
                        rx={9.829}
                        transform="translate(818.177 633.99)"
                        />
                        <text className="n" transform="translate(882 679.198)">
                        <tspan x={-28.052} y={0}>
                            {"Approve"}
                        </tspan>
                        </text>
                        <text className="n" transform="translate(882 649.112)">
                        <tspan x={-47.99} y={0}>
                            {"Quick Revision"}
                        </tspan>
                        </text>
                        <path
                        className="s"
                        d="M1022.694,698.318a18.543,18.543,0,0,0,18.543-18.542V631.849l-66.469,66.469Z"
                        />
                        <path
                        className="t"
                        d="M1041.237,631.849,982.491,643.8a21.71,21.71,0,0,0-16.326,27.965l8.6,26.556Z"
                        />
                        <g className="u">
                        <path
                            className="v"
                            d="M1022.321,698.318a18.916,18.916,0,0,0,18.916-18.916V631.849l-66.469,66.469Z"
                        />
                        </g>
                        <path
                        className="w"
                        d="M1041.313,19.924A18.421,18.421,0,0,0,1022.892,1.5H741.108a18.421,18.421,0,0,0-18.421,18.42V71.2h318.626Z"
                        />
                        <path
                        className="w"
                        d="M722.687,71.2V680.076a18.421,18.421,0,0,0,18.421,18.42h281.784a18.421,18.421,0,0,0,18.421-18.42V71.2Z"
                        />
                    </g>
                    </g>
                </svg>
            </div>
        );
    }
}

export default TaskInfoDialog;