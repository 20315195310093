import React, { Component } from "react";

class CustomButton extends Component {
	render() {
		return (
			<div className="custom-button">
				{this.props.title}
			</div>
		);
	}
}

export default CustomButton;