import React, { Component } from 'react'
import { Badge, Divider, Skeleton, Card, Typography, List, Button } from 'antd'
import firebase from '../../firebase'
import util from 'util'
import CTResult from '../Result'
import axios from 'axios'
import uuidv4 from '../../../helpers/uuidv4'

const { Title } = Typography

class Confirm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      chosenDesignId: props.chosenDesignId,
      chosenDesign: props.chosenDesign,
      userId: props.userId,
      userEmail: props.userEmail,
      taskName: props.taskName,
      taskBrief: props.taskBrief,
      showResult: false
    }
  }

  async componentDidMount() {
    let draftUploads = null

    try {
      draftUploads = await this.getDraftUploads()
    } catch (error) {}

    if (!draftUploads) {
      return
    }

    this.setState({ defaultFileList: draftUploads }, () =>
      this.setState({ loading: false })
    )
  }

  async getDraftUploads() {
    this.setState({ loading: true })

    const { userId, chosenDesignId } = this.state
    let uploadsOut = []

    try {
      await firebase.db
        .collection('createTasks')
        .doc('user')
        .collection(userId)
        .doc(chosenDesignId)
        .get()
        .then(function(doc) {
          if (doc.exists) {
            const docData = doc.data()

            let uploadsData = []

            try {
              uploadsData = JSON.parse(docData.uploads)

              uploadsOut = uploadsData.map(x => ({
                uid: x.uid,
                name: x.name,
                status: x.status,
                thumbUrl: x.thumbUrl,
                url: x.url
              }))
            } catch (uploadsDataError) {
              console.log('error parsing or mapping uploads')
              console.log(uploadsDataError)
            }
          } else {
            // doc.data() will be undefined in this case
            console.log('No such document!')
          }
        })
    } catch (error) {
      console.log('Error getting document:', error)
    }

    return uploadsOut
  }

  getDueDate(added_days) {
    var today = new Date()
    var addxdays = new Date()
    addxdays.setDate(today.getDate() + Number(added_days))
    const _addxdays = addxdays.toISOString().split('T')[0]

    return _addxdays
  }

  async setAsanaDueDate() {
    let due_on_2days = 0

    try {
      const x_day = new Date()
      const x_day_n = x_day.getDay()

      const due_n = () => {
        if (x_day_n === 5) return 3
        else if (x_day_n === 6) return 2
        else if (x_day_n === 7) return 1
        else return 1
      }

      due_on_2days = this.getDueDate(due_n())
    } catch (err) {
      console.log('Error failed getDueDate(2)')
      console.log(util.inspect(err))
    }

    return due_on_2days
  }

  async sendToAsana() {
    let clientProjectId = null
    let clientPlan = null
    let clientPlanEnumGid = null

    try {
      await firebase.db
        .collection('users')
        .doc(this.state.userId)
        .get()
        .then(function(doc) {
          if (doc.exists) {
            const docData = doc.data()

            clientProjectId = docData.onboarding.projects.asana.projectId
            clientPlan = docData.onboarding.user.plan
          } else {
            // doc.data() will be undefined in this case
            console.log('No such document!')
          }
        })
    } catch (error) {
      console.log('Error getting document:', error)
    }

    if (!clientProjectId || !clientPlan) {
      return { error: true, data: 'Unable to get projectId or plan' }
    }

    if (clientPlan === 'core' || clientPlan === 'Core') {
      clientPlanEnumGid = '588588498708771'
    } else if (clientPlan === 'plus' || clientPlan === 'Plus') {
      clientPlanEnumGid = '588588498708770'
    } else if (clientPlan === 'enterprise' || clientPlan === 'Enterprise') {
      clientPlanEnumGid = '588588498708769'
    } else if (clientPlan === 'support' || clientPlan === 'Support') {
      clientPlanEnumGid = '588588498708772'
    }

    // clientProjectId = "1115917309308539"
    const custom_fields_obj = {
      '588585222197901': '588585222197902', // status = TL review
      '588585222197910': '588585222197913', // Team = Design
      '588585222197915': '588585222197916', // New/Revision/Finalise = New
      '588588498708763': '588588498708764', // Priority = Normal
      '588588498708768': clientPlanEnumGid // Plan = Core
    }

    let out = { error: true, data: null }

    try {
      const asanaReq = await axios({
        method: 'POST',
        url: 'https://app.asana.com/api/1.0/tasks',
        headers: {
          Authorization: `Bearer 0/4089f7d1f889af6814aabe2213be272c`,
          'Content-Type': 'application/json'
          //   "Asana-Enable": "new_rich_text"
        },
        data: {
          data: {
            due_on: `${await this.setAsanaDueDate()}`,
            assignee: 'invoices@pebbled.io',
            notes: `__START_VIEW_IN_IGLOO__\nDescription:\n${
              this.state.taskBrief
            }\n--------------------------------------------------\n\nFiles:\n${this.state.defaultFileList.map(
              x => `Name: ${x.name}\nUrl: ${x.url}\n\n`
            )}\n__END_VIEW_IN_IGLOO__`,
            name: `${this.state.taskName} - ${this.state.chosenDesign} ~! new / Design`,
            workspace: '379613479698836',
            projects: ['1109444985881445', clientProjectId],
            custom_fields: custom_fields_obj
          }
        }
      }).then(x => x.data)

      out = { error: false, data: asanaReq }
    } catch (error) {
      console.log('Error creating asana task')
      console.log(error)
    }

    return out
  }

  async getTempTask() {
    const { userId, chosenDesignId } = this.state
    let out = null

    try {
      await firebase.db
        .collection('createTasks')
        .doc('user')
        .collection(userId)
        .doc(chosenDesignId)
        .get()
        .then(function(doc) {
          if (doc.exists) {
            const docData = doc.data()

            out = docData
          } else {
            // doc.data() will be undefined in this case
            console.log('No such document!')
          }
        })
    } catch (error) {
      console.log('Error getting document:', error)
    }

    return out
  }

  async saveSentBriefToFirebase(obj, asanaTaskGid, failed = false) {
    const { chosenDesign, chosenDesignId, userId, userEmail } = this.state

    const dateStamp = new Date()
    const nowDate = new Date()
    const secondsSinceEpoch = Math.round(nowDate.getTime() / 1000)

    let newObj = Object.assign({}, obj)
    newObj.status = 'sent'
    newObj.asanaId = asanaTaskGid ? asanaTaskGid : null
    newObj.designId = this.state.chosenDesignId
    newObj.id = `st-${secondsSinceEpoch}`
    newObj.submissionDate = dateStamp

    if (!failed) {
      newObj.failed = false
      try {
        await firebase.db
          .collection('sentTasks')
          .doc('user')
          .collection(userId)
          .doc(`st-${secondsSinceEpoch}`)
          .set(newObj, { merge: true })
          .then(function() {
            console.log('Document successfully written!')
          })
      } catch (error) {
        console.error('Error writing document: ', error)
      }
    } else {
      newObj.failed = true
      try {
        await firebase.db
          .collection('failedTasks')
          .doc('user')
          .collection(userId)
          .doc(`st-${secondsSinceEpoch}`)
          .set(newObj, { merge: true })
          .then(function() {
            console.log('Document successfully written!')
          })
      } catch (error) {
        console.error('Error writing document: ', error)
      }
    }
  }

  handleSubmitTask = async e => {
    this.setState({ loading: true })
    // send task to Asana

    const dateStamp = new Date()
    const nowDate = new Date()
    const secondsSinceEpoch = Math.round(nowDate.getTime() / 1000)

    const sendAsanaResult = await this.sendToAsana()

    if (!sendAsanaResult.error) {
      this.setState({
        isSuccessful: true,
        asanaTaskGid: sendAsanaResult.data.data.gid
      })

      // send email to client - OK
      // send notification to slack - OK
      // copy to sentTasks - OK
      // remove from createTasks *

      // clear createTasks data to avoid accidental uploads deletion

      let _getTempTask = null
      try {
        _getTempTask = await this.getTempTask()
        await this.saveSentBriefToFirebase(
          _getTempTask,
          sendAsanaResult.data.data.gid,
          false
        )
      } catch (error) {
        console.log('error setting complete task location')
      }

      try {
        await firebase.db
          .collection('createTasks')
          .doc('user')
          .collection(this.state.userId)
          .doc(this.state.chosenDesignId)
          .set(
            {
              uploads: ''
            },
            { merge: true }
          )
      } catch (filesError) {
        console.log('Error updating uploads field')
        console.log(filesError)
      }

      try {
        await axios(
          'https://us-central1-igloo-8c65d.cloudfunctions.net/transactional/email/task/created',
          {
            method: 'POST',
            data: {
              email: this.state.userEmail,
              task: {
                name: this.state.taskName,
                description: this.state.taskBrief,
                type: this.state.chosenDesign,
                submissionDate: dateStamp
              }
            }
          }
        )
      } catch (error) {
        console.log('Error sending task created email')
        console.log(error)
      }

      try {
        await axios(
          'https://us-central1-igloo-8c65d.cloudfunctions.net/tests/send-slack-notification/task/submit',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            data: {
              userId: this.state.userId,
              email: this.state.userEmail,
              task: {
                name: this.state.taskName,
                description: this.state.taskBrief,
                type: this.state.chosenDesign,
                submissionDate: dateStamp
              }
            }
          }
        )
      } catch (error) {
        console.log('Error sending task created slack notification')
        console.log(error)
      }
    } else {
      this.setState({ isSuccessful: false, asanaTaskGid: null })

      let _getTempTask = null
      try {
        _getTempTask = await this.getTempTask()
        await this.saveSentBriefToFirebase(_getTempTask, null, true)
      } catch (error) {
        console.log('error setting complete task location')
      }
    }

    this.setState({ loading: false, showResult: true })
  }

  render() {
    return (
      <div className="alignleft keep-line-breaks">
        {this.state.loading && (
          <div className="loader-padding">
            <Skeleton active />
            <Divider />
            <h3>Loading please wait...</h3>
            {
              (window.document.getElementsByClassName(
                'ct-prev'
              )[0].disabled = true)
            }
          </div>
        )}

        {!this.state.loading && this.state.showResult && (
          <div>
            <CTResult
              isSuccessful={this.state.isSuccessful}
              chosenDesign={this.state.chosenDesign}
              userEmail={this.state.userEmail}
              userId={this.state.userId}
              taskName={this.state.taskName}
              taskBrief={this.state.taskBrief}
            />
          </div>
        )}

        {!this.state.loading && !this.state.showResult && (
          <div>
            {
              (window.document.getElementsByClassName(
                'ct-prev'
              )[0].disabled = false)
            }
            <div className="aligncenter">
              <Title>"{this.state.chosenDesign}" Details</Title>
              <h3>Please confirm your task details before submitting.</h3>
            </div>
            <Divider dashed />
            <Card
              type="inner"
              title="Task Name:"
              // extra={<a href="#">More</a>}
            >
              {this.state.taskName}
            </Card>
            <Card
              style={{ marginTop: 16 }}
              type="inner"
              title="Task Description:"
              // extra={<a href="#">More</a>}
            >
              {this.state.taskBrief}
            </Card>
            <Card
              style={{ marginTop: 16 }}
              type="inner"
              title="Task Files:"
              // extra={<a href="#">More</a>}
            >
              <List
                size="small"
                bordered
                dataSource={this.state.defaultFileList}
                renderItem={item => (
                  <div>
                    <List.Item>
                      <Badge status="success" />
                      <a href={item.url} target="_blank">
                        {item.name}
                      </a>
                    </List.Item>
                  </div>
                )}
              />
            </Card>
            <Divider dashed />
            <div className="flexy-force">
              <Button
                type="primary"
                key="tasksubmit"
                onClick={this.handleSubmitTask}
              >
                Submit Task
              </Button>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default Confirm
