import React, { Component } from "react";
import Grid from '@material-ui/core/Grid';

import InfoList from "./InfoList";

class TaskInfo extends Component {
	render() {
		const { title, info } = this.props;
		// console.log('info', this.props.info);

		let infoList = info.map((val, key) => {
		  return <InfoList key={key} taskLabel={val.taskLabel} amount={val.amount} />;
		});

		return (
			<Grid item md={4}>
				<div className="task-info-wrapper">
					<div className="text-label">
						{title}
					</div>
					<div className="box-wrap with-shadow">
						<div className="info-content">
							<ul className="task-info-list">
								{infoList}
							</ul>
						</div>
					</div>
				</div>
			</Grid>
		);
	}
}

export default TaskInfo;