import React, { Component } from 'react'
import util from 'util'
import firebase from '../firebase'

class Logout extends Component {
  constructor(props) {
    super(props)
  }

  state = {
    loggedIn: true
  }

  componentDidMount() {
    this.logout()
  }

  logout = async () => {
    console.log('LogOut')
    // window.Intercom('shutdown')
    await firebase.logout()
    this.setState({ loggedIn: false })
    window.location.replace('/login')
  }

  render() {
    return <div></div>
  }
}

export default Logout
