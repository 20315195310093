import React, { Component } from "react";

class InfoList extends Component {
	render() {
		const { taskLabel, amount } = this.props;
		return (
			<li>
				<div className="label-info">
					{taskLabel}
				</div>
				<div className="amount">
					{amount}
				</div>
			</li>
		);
	}
}

export default InfoList;