import React, { Component } from "react"
import util from "util"

class DelinqDialog extends Component {
    constructor(props) {
        super(props)
    }

    state = {

    }

    invokeBaremetricsRecover() {
        // !function(){if(window.barepay&&window.barepay.created)window.console&&console.error&&console.error("Barepay snippet included twice.");else{window.barepay={created:!0};var a=document.createElement("script");a.src="https://baremetrics-dunning.baremetrics.com/js/application.js",a.async=!0;var b=document.getElementsByTagName("script")[0];b.parentNode.insertBefore(a,b),

        // window.barepay.params = {
        //     access_token_id: "4gDdmHhetCfTx3", // Your Recover API public key
        //     customer_oid: "105568115" // Customer ID whose card you're looking to update
        // }

        // }}();
    }

    componentDidMount() {
        this.invokeBaremetricsRecover()
    }

    componentWillMount() {

    }

    render() {
        return (
            <barepay></barepay>
        )
    }

}

export default DelinqDialog;