import React, { Component } from 'react'
import { Upload, Icon, message, Divider, Skeleton } from 'antd'
import Dropboxy from '../../../helpers/dropbox'
import firebase from '../../firebase'
import util from 'util'

const { Dragger } = Upload

class BriefUpload extends Component {
  constructor(props) {
    super(props)
    this.state = {
      chosenDesignId: props.chosenDesignId,
      userId: props.userId
    }
  }

  state = {
    loading: false,
    fileUid: null,
    fileName: null,
    defaultFileList: []
  }

  testFilesList = [
    {
      uid: 'aaa',

      name: 'bea7040f63527c6a2f71c3a36696e1db.jpg',
      status: 'done',
      thumbUrl:
        'https://firebasestorage.googleapis.com/v0/b/igloo-8c65d.appspot.com/o/user%2Fa86f0e1f-1ec7-4774-b851-1b1bf7d80b9c%2Ftasks%2Fct-ea5a2cbba45b%2Frc-upload-1575543195312-5%2Fbea7040f63527c6a2f71c3a36696e1db.jpg?alt=media&token=f7701b57-e7a7-44a0-8839-1ef30fd86b11',
      url:
        'https://firebasestorage.googleapis.com/v0/b/igloo-8c65d.appspot.com/o/user%2Fa86f0e1f-1ec7-4774-b851-1b1bf7d80b9c%2Ftasks%2Fct-ea5a2cbba45b%2Frc-upload-1575543195312-5%2Fbea7040f63527c6a2f71c3a36696e1db.jpg?alt=media&token=f7701b57-e7a7-44a0-8839-1ef30fd86b11'
    },
    {
      uid: 'BBB',
      name: '70e9d094d2a3621db2ba688ca53538b1.jpg',
      status: 'done',
      thumbUrl:
        'https://firebasestorage.googleapis.com/v0/b/igloo-8c65d.appspot.com/o/user%2Fa86f0e1f-1ec7-4774-b851-1b1bf7d80b9c%2Ftasks%2Fct-ea5a2cbba45b%2Frc-upload-1575543195312-6%2F70e9d094d2a3621db2ba688ca53538b1.jpg?alt=media&token=70f66bd8-2a83-4e23-8930-830e9cb506bb',
      url:
        'https://firebasestorage.googleapis.com/v0/b/igloo-8c65d.appspot.com/o/user%2Fa86f0e1f-1ec7-4774-b851-1b1bf7d80b9c%2Ftasks%2Fct-ea5a2cbba45b%2Frc-upload-1575543195312-6%2F70e9d094d2a3621db2ba688ca53538b1.jpg?alt=media&token=70f66bd8-2a83-4e23-8930-830e9cb506bb'
    }
  ]

  async uploadOnChange(info) {
    console.log('onChange info')
    console.log(info)

    const { status } = info.file
    const file = info.file

    if (status === 'uploading') {
      window.document.getElementsByClassName('ct-prev')[0].disabled = true
      window.document.getElementsByClassName('ct-next')[0].disabled = true
    }

    if (status !== 'uploading') {
      console.log(info.file, info.fileList)

      setTimeout(() => {
        window.document.getElementsByClassName('ct-prev')[0].disabled = false
        window.document.getElementsByClassName('ct-next')[0].disabled = false
      }, 3500)
    }
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`)
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`)
    }

    const storage = firebase.storage
    const currentUserId = await firebase.getCurrentUserId()
    const storageRef = await storage.ref()

    if (info.file.status === 'removed') {
      // Create a reference to the file to delete
      const fileRef = storageRef.child(
        `user/${currentUserId}/tasks/${this.props.chosenDesignId}/${info.file.uid}/${info.file.name}`
      )

      // Delete the file
      await fileRef
        .delete()
        .then(function() {
          // File deleted successfully
          console.log('deleted')
        })
        .catch(function(error) {
          // Uh-oh, an error occurred!
          console.log(error)
          console.log('error deleting file')
        })
    }

    // const fileListOut = info.fileList.map(x => {
    //   console.log("fileListOut")
    //   console.log(x)

    //   let tempObj = x.response
    //   tempObj.xhr = ""
    //   return tempObj
    // })

    console.log('info.fileList 2')
    console.log(info.fileList)

    if (!info.fileList) {
      return
    }

    let fileListOut = []

    try {
      fileListOut = info.fileList.map(x => ({
        uid: x.uid,
        name: x.response.name,
        status: x.response.status,
        thumbUrl: x.response.thumbUrl,
        url: x.response.url,
        meta: x
      }))
    } catch (fileListOutError) {
      fileListOut = info.fileList
    }

    try {
      await firebase.db
        .collection('createTasks')
        .doc('user')
        .collection(this.state.userId)
        .doc(this.state.chosenDesignId)
        .set(
          {
            uploads: JSON.stringify(fileListOut)
          },
          { merge: true }
        )
    } catch (filesError) {
      console.log('Error updating uploads field')
      console.log(filesError)
    }
  }

  async uploadCustomRequest({ onSuccess, onError, file }) {
    const storage = firebase.storage
    const storageRef = await storage.ref()

    const fileRef = storageRef.child(
      `user/${this.state.userId}/tasks/${this.props.chosenDesignId}/${file.uid}/${file.name}`
    )

    let image = null

    await fileRef
      .put(file)
      .then(function(snapshot) {
        console.log('Uploaded a blob or file!')

        console.log('snapshot')
        console.log(snapshot)
      })
      .catch(errPut => {
        console.log('image error', util.inspect(errPut))
        return onError(errPut)
      })

    console.log(
      `user/${this.state.userId}/tasks/${this.props.chosenDesignId}/${file.uid}/${file.name}`
    )

    await storage
      .ref()
      .child(
        `user/${this.state.userId}/tasks/${this.props.chosenDesignId}/${file.uid}/${file.name}`
        // "/user/a86f0e1f-1ec7-4774-b851-1b1bf7d80b9c/task/ct-d2ab1f27e69c/rc-upload-1575488784832-2/70e9d094d2a3621db2ba688ca53538b1.jpg"
      )
      .getDownloadURL()
      .then(function(url) {
        console.log('previewFile URL:', url)

        onSuccess({
          name: file.name,
          status: 'done',
          url: url,
          thumbUrl: url
        })
        // return url
      })
      .catch(function(errorDownUrl) {
        // Handle any errors
        console.log('Error getting getDownloadURL')
        console.log(errorDownUrl)
      })
  }

  propsUpload = {
    name: 'fileout',
    listType: 'defaultFileList',
    // defaultFileList: this.testFilesList,
    multiple: true,
    beforeUpload(file) {
      // console.log('before upload')
    },
    async previewFile(file) {
      console.log('previewFile')
      console.log(file)
      // Your process logic. Here we just mock to the same file
      // return fetch('https://next.json-generator.com/api/json/get/4ytyBoLK8', {
      //   method: 'POST',
      //   body: file
      // })
      //   .then(res => res.json())
      //   .then(({ thumbnail }) => thumbnail)

      // const storage = firebase.storage
      // const currentUserId = await firebase.getCurrentUserId()

      // const storageRef = await storage.ref()

      // return storageRef

      // console.log(
      //   `/user/${thisy.state.userId}/tasks/${thisy.props.chosenDesignId}/${file.uid}/${file.name}`
      // )
      // await storage
      //   .ref()
      //   .child(
      //     `/user/${thisy.state.userId}/tasks/${thisy.props.chosenDesignId}/${file.uid}/${file.name}`
      //     // "/user/a86f0e1f-1ec7-4774-b851-1b1bf7d80b9c/task/ct-d2ab1f27e69c/rc-upload-1575488784832-2/70e9d094d2a3621db2ba688ca53538b1.jpg"
      //   )
      //   .getDownloadURL()
      //   .then(function(url) {
      //     // `url` is the download URL for 'images/stars.jpg'

      //     // This can be downloaded directly:
      //     // var xhr = new XMLHttpRequest();
      //     // xhr.responseType = 'blob';
      //     // xhr.onload = function(event) {
      //     //   var blob = xhr.response;
      //     // };
      //     // xhr.open('GET', url);
      //     // xhr.send();

      //     // Or inserted into an <img> element:
      //     // var img = document.getElementById('myimg');
      //     // img.src = url;

      //     console.log("previewFile URL:", url)

      //     return url
      //   })
      //   .catch(function(errorDownUrl) {
      //     // Handle any errors
      //     console.log("Error getting getDownloadURL")
      //     console.log(errorDownUrl)
      //   })

      // const reader = new FileReader()
      // let fileOut = null

      // reader.readAsDataURL(file)
      // reader.onload = function(e) {
      //   const content = reader.result
      //   // console.log("file content", content)

      //   fileOut = content
      //   return content
      // }

      // return fileOut
    }
  }

  async getDraftUploads() {
    this.setState({ loading: true })

    const { userId, chosenDesignId } = this.state
    let uploadsOut = []

    try {
      await firebase.db
        .collection('createTasks')
        .doc('user')
        .collection(userId)
        .doc(chosenDesignId)
        .get()
        .then(function(doc) {
          if (doc.exists) {
            const docData = doc.data()

            let uploadsData = []

            try {
              uploadsData = JSON.parse(docData.uploads)

              uploadsOut = uploadsData.map(x => ({
                uid: x.uid,
                name: x.name,
                status: x.status,
                thumbUrl: x.thumbUrl,
                url: x.url
              }))
            } catch (uploadsDataError) {
              console.log('error parsing or mapping uploads')
              console.log(uploadsDataError)
            }
          } else {
            // doc.data() will be undefined in this case
            console.log('No such document!')
          }
        })
    } catch (error) {
      console.log('Error getting document:', error)
    }

    return uploadsOut
  }

  async componentDidMount() {
    console.log('this.props brief index.js')
    console.log(this.props)

    let draftUploads = null

    try {
      draftUploads = await this.getDraftUploads()

      console.log(draftUploads)
    } catch (error) {
      //
    }

    if (!draftUploads) {
      return
    }

    this.setState({ defaultFileList: draftUploads }, () =>
      this.setState({ loading: false })
    )
  }

  render() {
    const propsUploadObj = this.propsUpload
    return (
      <div>
        {this.state.loading && (
          <div className="loader-padding">
            <Skeleton active />
            <Divider />
          </div>
        )}

        {!this.state.loading && (
          <Dragger
            {...propsUploadObj}
            customRequest={({ onSuccess, onError, file }) =>
              this.uploadCustomRequest({ onSuccess, onError, file })
            }
            onChange={info => this.uploadOnChange(info)}
            defaultFileList={this.state.defaultFileList}
          >
            <p className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload.
            </p>
          </Dragger>
        )}
      </div>
    )
  }
}

export default BriefUpload
