import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import update from 'immutability-helper'
import TaskInfo from './TaskInfo'
import TaskProgress from './TaskProgress'
import util from 'util'
import firebase from '../../components/firebase'

import IntercomLoad from '../../helpers/IntercomLoad'

// import EventTargetPolyfill from '@mattkrick/event-target-polyfill'
// import EventSourcePolyfill from '@mattkrick/eauvent-source-polyfill'

// window.EventTarget = EventTargetPolyfill
// window.EventSource = EventSourcePolyfill

let igloo_user

class Dashboard extends Component {
  constructor(props) {
    super(props)

    firebase.setStorage(firebase.auth.currentUser.uid)

    igloo_user = JSON.parse(localStorage.getItem('igloo_user'))
    this.state.user = igloo_user
  }

  state = {
    user: null,
    taskProgressList: [],
    taskProgressListInProgress: [],
    taskProgressListQueued: [],
    taskProgressListReadyToReview: [],
    taskProgressListTeamReview: [],
    taskProgressListInfoNeeded: [],
    taskProgressListCompleted: [],
    taskOverviewList: [
      {
        title: 'Tasks submitted',
        info: [
          {
            taskLabel: 'This month',
            amount: '-'
          },
          {
            taskLabel: 'All Time',
            amount: '-'
          }
        ]
      },
      {
        title: 'Ongoing tasks',
        info: [
          {
            taskLabel: 'In progress',
            amount: '-'
          },
          {
            taskLabel: 'Feedback',
            amount: '-'
          }
        ]
      },
      {
        title: 'Completed tasks',
        info: [
          {
            taskLabel: 'This month',
            amount: '-'
          },
          {
            taskLabel: 'All Time',
            amount: '-'
          }
        ]
      }
    ]
  }

  tasks_sumitted_func = async tasks => {
    let month_we_are_in = new Date().getMonth()
    let this_month = 0
    const all = tasks.length

    for (let task of tasks) {
      let taskMonth = new Date(task.created_at).getMonth()

      if (month_we_are_in === taskMonth) {
        this_month++
      }
    }

    return { month: this_month, all: all }
  }

  tasks_completed_func = async tasks => {
    let month_we_are_in = new Date().getMonth()
    let complete_month_count = 0
    let complete_all_count = 0

    for (let task of tasks) {
      let taskMonth = new Date(task.created_at).getMonth()

      try {
        for (let custom_field of task.custom_fields) {
          if (custom_field.name === 'Status') {
            let status = custom_field.enum_value.name

            if (status.toLowerCase().includes('complete')) {
              complete_all_count++

              if (month_we_are_in === taskMonth) {
                complete_month_count++
              }
            }
          }
        }
      } catch (err) {
        console.log(util.inspect(err))
      }
    }

    return {
      month: complete_month_count,
      all: complete_all_count
    }
  }

  tasks_ongoing_func = async tasks => {
    let in_progress_count = 0
    let feedback_count = 0

    try {
      for (let task of tasks) {
        try {
          for (let custom_field of task.custom_fields) {
            try {
              if (custom_field.name === 'Status') {
                let status = custom_field.enum_value.name

                if (status.toLowerCase().includes('in progress')) {
                  in_progress_count++
                }

                if (status.toLowerCase().includes('ready to review')) {
                  feedback_count++
                }
              }
            } catch (errIf) {
              console.log(util.inspect(errIf))
            }
          }
        } catch (errForTaks) {
          console.log(util.inspect(errForTaks))
        }
      }
    } catch (err) {
      console.log(util.inspect(err))
    }

    return {
      inprogress: in_progress_count,
      feedback: feedback_count
    }
  }

  getTasks = async userid => {
    return firebase.db
      .collection('tasks')
      .where('userid', '==', userid)
      .get()
      .then(async function(querySnapshot) {
        let tasks = []
        querySnapshot.forEach(function(doc) {
          // console.log(doc.id, ' => ', doc.data())
          tasks.push(doc.data())
        })

        return tasks
      })
  }

  async fetchInfoSubmittedCount() {
    try {
      const tasks = await this.getTasks(this.state.user.id)

      const tasks_responses = await Promise.all([
        this.tasks_sumitted_func(tasks),
        this.tasks_ongoing_func(tasks),
        this.tasks_completed_func(tasks)
      ])

      const tasks_sumitted = tasks_responses[0]
      const tasks_ongoing = tasks_responses[1]
      const tasks_completed = tasks_responses[2]
      const total_running =
        Number(tasks_sumitted.all) - Number(tasks_completed.all)

      const res = {
        submitted: tasks_sumitted,
        ongoing: tasks_ongoing,
        completed: tasks_completed,
        totalrunning: total_running
      }

      this.props.onHandleTotalRunning(res.totalrunning)
      let submitted_month,
        submitted_all,
        ongoing_inprogress,
        ongoing_feedback,
        completed_month,
        completed_all

      try {
        submitted_month = res.submitted.month
        submitted_all = res.submitted.all
        ongoing_inprogress = res.ongoing.inprogress
        ongoing_feedback = res.ongoing.feedback
        completed_month = res.completed.month
        completed_all = res.completed.all
      } catch (errSubs) {
        console.log(`Error setting stats - ${errSubs}`)
        submitted_month = this.state.taskOverviewList[0].info[0].amount
        submitted_all = this.state.taskOverviewList[0].info[1].amount
        ongoing_inprogress = this.state.taskOverviewList[1].info[0].amount
        ongoing_feedback = this.state.taskOverviewList[0].info[1].amount
        completed_month = this.state.taskOverviewList[2].info[0].amount
        completed_all = this.state.taskOverviewList[0].info[1].amount
      }

      this.setState({
        taskOverviewList: [
          {
            title: 'Tasks submitted',
            info: [
              {
                taskLabel: 'This month',
                amount: submitted_month
              },
              {
                taskLabel: 'All Time',
                amount: submitted_all
              }
            ]
          },
          {
            title: 'Ongoing tasks',
            info: [
              {
                taskLabel: 'In progress',
                amount: ongoing_inprogress
              },
              {
                taskLabel: 'Feedback',
                amount: ongoing_feedback
              }
            ]
          },
          {
            title: 'Completed tasks',
            info: [
              {
                taskLabel: 'This month',
                amount: completed_month
              },
              {
                taskLabel: 'All Time',
                amount: completed_all
              }
            ]
          }
        ]
      })
      // })
    } catch (error) {
      //err
      console.log('Error getting task info, check internet connection.')
      console.log(util.inspect(error))
      this.forceUpdate()
      this.setState({ state: this.state })
    }
  }

  getMonthString(month_num) {
    let out
    switch (month_num) {
      case 0:
        out = 'Jan'
        break
      case 1:
        out = 'Feb'
        break
      case 2:
        out = 'Mar'
        break
      case 3:
        out = 'Apr'
        break
      case 4:
        out = 'May'
        break
      case 5:
        out = 'Jun'
        break
      case 6:
        out = 'Jul'
        break
      case 7:
        out = 'Aug'
        break
      case 8:
        out = 'Sep'
        break
      case 9:
        out = 'Oct'
        break
      case 10:
        out = 'Nov'
        break
      case 11:
        out = 'Dec'
        break
    }
    return out
  }

  // async updateAccount() {
  //   let self = this
  //   try {
  //     const igloo_user_updated = JSON.parse(localStorage.getItem('igloo_user'))

  //     return fetch(
  //       `https://get-existing-user-info-igloo-api-v2/?id=${
  //         igloo_user_updated.id
  //       }`
  //     )
  //       .then(resData => resData.json())
  //       .then(resData => {
  //         const user_obj = {
  //           email: resData.email,
  //           id: resData.userid,
  //           name: resData.name,
  //           plan: resData.onboarding.user.plan,
  //           project_id: resData.onboarding.projects.asana.projectId,
  //           status: resData.onboarding.user.status
  //         }

  //         localStorage.setItem('igloo_user', JSON.stringify(user_obj))
  //         self.setState({
  //           user: user_obj
  //         })

  //         return user_obj
  //       })
  //   } catch (err) {
  //     console.log('error getting user info')
  //     console.log(util.inspect(err))
  //     return err
  //   }
  // }

  async updateTasks() {
    const self = this

    const updateReq = await firebase.db
      .collection('tasks')
      .where('userid', '==', this.state.user.id)
      .onSnapshot(querySnapshot => {
        let changeType = false
        let changeData = false
        querySnapshot.docChanges().map(function(change) {
          changeType = change.type
          changeData = change.doc.data()

          // console.log('updateTasks - incoming task update')
          // console.log(changeData)
        })

        // self.fetchTasksHelper(changeData, true, changeType)
        // self.fetchTasks()
        self.fetchTasksHelper(querySnapshot)
      })
  }

  fetchTasksHelper = async (querySnapshot, isUpdate = false, _type = false) => {
    let self = this
    let source
    let resOut = []

    if (!isUpdate) {
      resOut = querySnapshot.docs.map(function(documentSnapshot) {
        return documentSnapshot.data()
      })
    } else if (isUpdate && _type) {
      resOut.push(querySnapshot)
    }

    // console.log(`fetchTasksHelper`)
    // console.log(`${util.inspect(resOut)}, ${isUpdate}, ${_type}`)

    let tracked_tasks_data = resOut
    let taskProgressList = []
    let taskProgressListCompleted = []
    let taskProgressListInProgress = []
    let taskProgressListInfoNeeded = []
    let taskProgressListQueued = []
    let taskProgressListReadyToReview = []
    let taskProgressListTeamReview = []
    let name,
      status,
      team,
      newrev = '-',
      datecreated,
      splitYear

    // console.log( JSON.stringify(tracked_tasks_data) )

    if (tracked_tasks_data && tracked_tasks_data.length > 0) {
      tracked_tasks_data.forEach(x => {
        if (x.custom_fields.length > 0) {
          try {
            datecreated = x.created_at
            datecreated = new Date(datecreated)

            splitYear = datecreated.getFullYear()
            splitYear = splitYear.toString()
            splitYear = `'${splitYear.substr(2)}`

            datecreated = `${datecreated.getDate()} ${this.getMonthString(
              datecreated.getMonth()
            )} ${splitYear}`

            // console.log( JSON.stringify(datecreated) )

            status = x.custom_fields.map(custom_field =>
              custom_field.name === 'Status'
                ? custom_field.enum_value.name
                : null
            )
            status = status.filter(item => item)
            status = status.filter(function(e) {
              return e.replace(/(\r\n|\n|\r)/gm, '')
            })
            status = status[0]
          } catch (error) {
            // err
            console.log('Error in init of task get')
            console.log(error)

            this.forceUpdate()
            this.setState({ state: this.state })
          }

          try {
            team = x.custom_fields.map(custom_field => {
              if (custom_field.name === 'Team')
                return custom_field.enum_value.name
            })

            team = team.filter(item => item)
            team = team.filter(function(e) {
              return e.replace(/(\r\n|\n|\r)/gm, '')
            })
            team = team[0]

            newrev = x.custom_fields.map(custom_field => {
              if (custom_field.name.includes('New/Revision'))
                return custom_field.enum_value.name
            })

            newrev = newrev.filter(item => item)
            newrev = newrev.filter(function(e) {
              return e.replace(/(\r\n|\n|\r)/gm, '')
            })
            newrev = newrev[0]
          } catch (err) {
            console.log(err)

            this.setState({ state: this.state })
          }

          try {
            if (status && status.length > 0) {
              if (status.toLowerCase().includes('tl review'))
                status = 'Team Review'
              if (status.toLowerCase().includes('in progress'))
                status = 'In Progress'
              if (status.toLowerCase().includes('clarification'))
                status = 'Info Needed'
              if (status.toLowerCase().includes('ready to review'))
                status = 'Ready To Review'
              if (status.toLowerCase().includes('sent/complete'))
                status = 'Completed'
              if (status.toLowerCase().includes('queued')) status = 'Queued'
            } else {
              status = '-'
              newrev = '-'
              team = '-'
            }
          } catch (error) {
            // err
            console.log('Error getting task status')
            console.log(error)

            this.forceUpdate()
            this.setState({ state: this.state })
          }

          try {
            if (x.name.includes('~!')) {
              name = /[^~!]*/.exec(x.name)[0]

              var suffix_s = name.substring(name.length - 2)
              if (suffix_s === '- ') {
                name = name.slice(0, -2)
                name = name.trim()
              }
            } else {
              name = x.name
            }
          } catch (error) {
            console.log('Error getting task name')
            console.log(error)

            this.forceUpdate()
            this.setState({ state: this.state })
          }

          if (
            status.includes('Info Needed') ||
            status.includes('info needed')
          ) {
            taskProgressListInfoNeeded.push({
              title: name,
              id: `ID: ${x.gid.toString().slice(-5)}`,
              status: status,
              full_id: x.gid,
              team: team,
              newrev: newrev,
              created_at: datecreated,
              raw: JSON.stringify(x)
            })
          }

          if (
            status.includes('Ready To Review') ||
            status.includes('ready to review')
          ) {
            taskProgressListReadyToReview.push({
              title: name,
              id: `ID: ${x.gid.toString().slice(-5)}`,
              status: status,
              full_id: x.gid,
              team: team,
              newrev: newrev,
              created_at: datecreated,
              raw: JSON.stringify(x)
            })
          }

          if (
            status.includes('Team Review') ||
            status.includes('team review')
          ) {
            taskProgressListTeamReview.push({
              title: name,
              id: `ID: ${x.gid.toString().slice(-5)}`,
              status: status,
              full_id: x.gid,
              team: team,
              newrev: newrev,
              created_at: datecreated,
              raw: JSON.stringify(x)
            })
          }

          if (
            status.includes('In Progress') ||
            status.includes('in progress')
          ) {
            taskProgressListInProgress.push({
              title: name,
              id: `ID: ${x.gid.toString().slice(-5)}`,
              status: status,
              full_id: x.gid,
              team: team,
              newrev: newrev,
              created_at: datecreated,
              raw: JSON.stringify(x)
            })
          }

          if (status.includes('Queued') || status.includes('queued')) {
            taskProgressListQueued.push({
              title: name,
              id: `ID: ${x.gid.toString().slice(-5)}`,
              status: status,
              full_id: x.gid,
              team: team,
              newrev: newrev,
              created_at: datecreated,
              raw: JSON.stringify(x)
            })
          }

          if (status.includes('Completed') || status.includes('completed')) {
            taskProgressListCompleted.push({
              title: name,
              id: `ID: ${x.gid.toString().slice(-5)}`,
              status: status,
              full_id: x.gid,
              team: team,
              newrev: newrev,
              created_at: datecreated,
              raw: JSON.stringify(x)
            })
          }

          if (isUpdate === false || isUpdate) {
            this.setState({
              taskProgressList: [],
              taskProgressListInfoNeeded: [],
              taskProgressListReadyToReview: [],
              taskProgressListTeamReview: [],
              taskProgressListInProgress: [],
              taskProgressListQueued: [],
              taskProgressListCompleted: []
            })

            this.setState({
              // taskProgressList: [
              //   ...taskProgressListInfoNeeded,
              //   ...taskProgressListReadyToReview,
              //   ...taskProgressListTeamReview,
              //   ...taskProgressListInProgress,
              //   ...taskProgressListQueued,
              //   ...taskProgressListCompleted
              // ],
              taskProgressList,
              taskProgressListInfoNeeded,
              taskProgressListReadyToReview,
              taskProgressListTeamReview,
              taskProgressListInProgress,
              taskProgressListQueued,
              taskProgressListCompleted
            })
          } else if (isUpdate) {
            // if (_type === 'modified') {
            //   self.setState(state => {
            //     let newStateObj = state
            //     const stateArrayNames = [
            //       'taskProgressList',
            //       'taskProgressListInfoNeeded',
            //       'taskProgressListReadyToReview',
            //       'taskProgressListTeamReview',
            //       'taskProgressListInProgress',
            //       'taskProgressListQueued',
            //       'taskProgressListCompleted'
            //     ]
            //     console.log(util.inspect(newStateObj))
            //     const list = stateArrayNames.map(stateArrayName => {
            //       const stateArrays = state[stateArrayName].map(stateArray => {
            //         console.log(stateArray)
            //         return stateArray
            //       })
            //       try {
            //         stateArrays.map(item => {
            //           if (`${item.full_id}` === x.gid) {
            //             console.log(`found match`)
            //             console.log(`${item.full_id} === ${x.gid}`)
            //             console.log(`newStateObj`)
            //             console.log(newStateObj)
            //           }
            //         })
            //       } catch (error) {}
            //     })
            //     console.log('list')
            //     console.log(util.inspect(list))
            //     // return {
            //     //   list
            //     // }
            //   })
            // }
          }

          return {
            title: name,
            id: `ID: ${x.gid.toString().slice(-5)}`,
            status: status,
            full_id: x.gid,
            team: team,
            newrev: newrev,
            created_at: datecreated,
            raw: JSON.stringify(x)
          }
        } else {
          // skipping sub task
        }
      })

      // taskProgressList = tracked_tasks_data.map(x => {})
    } else {
      console.log(
        'ERR in - tracked_tasks_data && tracked_tasks_data.length > 0'
      )
    }
  }

  fetchTasks(data = false) {
    let self = this
    let source

    try {
      if (data) {
        self.fetchTasksHelper(data)
      } else {
        firebase.db
          .collection('tasks')
          .where('userid', '==', this.state.user.id)
          .get()
          .then(querySnapshot => {
            self.fetchTasksHelper(querySnapshot)
          })
      }
    } catch (error) {
      // handle fetch error event
      console.log(
        'Error fetching tasks, please check your internet connection.'
      )
    }
  }

  async componentDidMount() {
    // igloo_user = JSON.parse(localStorage.getItem('igloo_user'))

    try {
      // this.fetchTasks()
      // this.fetchInfoSubmittedCount()
      this.updateTasks()
    } catch (error) {
      console.log('Error updating tasks')
    }

    // this.fetchTasksSSE()
    // this.timer = setInterval(async () => {
    //   this.fetchTasks()
    //   this.fetchInfoSubmittedCount()
    //   // this.updateAccount()
    // }, 1500000)
  }

  componentWillUnmount() {
    // this.timer = null
  }

  async componentWillMount() {
    if (!firebase.auth.currentUser.uid) {
      // not logged in
      // alert('Please login first')
      this.props.history.replace('/logout')
      return null
    }

    // this.fetchTasks()
    this.fetchInfoSubmittedCount()
  }

  render() {
    let taskOverview, taskProgress
    let taskInProgress,
      taskTeamReview,
      taskInfoNeeded,
      taskCompleted,
      taskQueued,
      taskReadyToReview

    // taskProgressListInfoNeeded,
    // taskProgressListReadyToReview,
    // taskProgressListTeamReview,
    // taskProgressListInProgress
    // taskProgressListQueued,
    // taskProgressListCompleted

    try {
      taskOverview = this.state.taskOverviewList.map((val, key) => {
        return <TaskInfo key={key} title={val.title} info={val.info} />
      })

      taskProgress = this.state.taskProgressList.map((val, key) => {
        return <TaskProgress key={key} info={val} />
      })

      // split and seperated into cats

      // info needed
      try {
        if (this.state.taskProgressListInfoNeeded.length > 0) {
          taskInfoNeeded = this.state.taskProgressListInfoNeeded.map(
            (val, key) => {
              return <TaskProgress key={key} info={val} />
            }
          )
        } else {
          taskInfoNeeded = ''
        }
      } catch (errorTasks) {
        console.log(`Tasks taskInfoNeeded error - ${errorTasks}`)
      }
      // info needed end

      // ready to review
      try {
        if (this.state.taskProgressListReadyToReview.length > 0) {
          taskReadyToReview = this.state.taskProgressListReadyToReview.map(
            (val, key) => {
              return <TaskProgress key={key} info={val} />
            }
          )
        } else {
          taskReadyToReview = ''
        }
      } catch (errorTasks) {
        console.log(`Tasks taskReadyToReview error - ${errorTasks}`)
      }
      // ready to review end

      // team review
      try {
        if (this.state.taskProgressListTeamReview.length > 0) {
          taskTeamReview = this.state.taskProgressListTeamReview.map(
            (val, key) => {
              return <TaskProgress key={key} info={val} />
            }
          )
        } else {
          taskTeamReview = ''
        }
      } catch (errorTasks) {
        console.log(`Tasks taskTeamReview error - ${errorTasks}`)
      }
      // team review end

      // in progress
      try {
        if (this.state.taskProgressListInProgress.length > 0) {
          taskInProgress = this.state.taskProgressListInProgress.map(
            (val, key) => {
              return <TaskProgress key={key} info={val} />
            }
          )
        } else {
          taskInProgress = ''
        }
      } catch (errorTasks) {
        console.log(`Tasks taskInProgress error - ${errorTasks}`)
      }
      // in progress end

      // queued
      try {
        if (this.state.taskProgressListQueued.length > 0) {
          taskQueued = this.state.taskProgressListQueued.map((val, key) => {
            return <TaskProgress key={key} info={val} />
          })
        } else {
          taskQueued = ''
        }
      } catch (errorTasks) {
        console.log(`Tasks taskQueued error - ${errorTasks}`)
      }
      // queued end

      // completed
      try {
        if (this.state.taskProgressListCompleted.length > 0) {
          taskCompleted = this.state.taskProgressListCompleted.map(
            (val, key) => {
              return <TaskProgress key={key} info={val} />
            }
          )
        } else {
          taskCompleted = ''
        }
      } catch (errorTasks) {
        console.log(`Tasks taskCompleted error - ${errorTasks}`)
      }
      // completed end
    } catch (error) {
      // error
      taskOverview = []
      taskProgress = []
      taskInProgress = []
      taskTeamReview = []
      taskInfoNeeded = []
      taskCompleted = []
      taskQueued = []
      taskReadyToReview = []
      console.log('Error getting task info')
      console.log(error)
    }

    return (
      <div className="component-wrapper">
        <div className="tasks-wrapper">
          <Grid container>{taskOverview}</Grid>
        </div>
        <div className="task-progress-wrap">
          {taskInfoNeeded && (
            <div className="prog-label-wrapper">
              <div className="text-label prog-label">Info Needed</div>
              {taskInfoNeeded}
            </div>
          )}

          {taskReadyToReview && (
            <div className="prog-label-wrapper">
              <div className="text-label prog-label">Ready To Review</div>
              {taskReadyToReview}
            </div>
          )}

          {taskTeamReview && (
            <div className="prog-label-wrapper">
              <div className="text-label prog-label">Team Review</div>
              {taskTeamReview}
            </div>
          )}

          {taskInProgress && (
            <div className="prog-label-wrapper">
              <div className="text-label prog-label">In Progress</div>
              {taskInProgress}
            </div>
          )}

          {taskQueued && (
            <div className="prog-label-wrapper">
              <div className="text-label prog-label">Queued</div>
              {taskQueued}
            </div>
          )}

          {taskCompleted && (
            <div className="prog-label-wrapper">
              <div className="text-label prog-label">Completed</div>
              {taskCompleted}
            </div>
          )}

          {/*          {taskProgress && (
            <div className="prog-label-wrapper">
              <div className="text-label prog-label">All</div>
              {taskProgress}
            </div>
          )}*/}
        </div>
      </div>
    )
  }
}

export default Dashboard
