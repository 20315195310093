import React, { Component } from "react"
import util from "util"
import axios from "axios"
import IntercomLoad from "./helpers/IntercomLoad"

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Prompt
} from "react-router-dom"

// import createBrowserHistory from 'history/createBrowserHistory'
// require("history").createBrowserHistory
import { createBrowserHistory } from "history"

// components
import Header from "./components/Header"
import WelcomeDialog from "./components/WelcomeDialog"
// import ErrorDialog from './components/ErrorDialogWarn'
import ErrorDialog from "./components/ErrorDialog"
import VideoComp from "./components/VideoComp"
import TaskInfoDialog from "./components/TaskInfoDialog"
import DelinqDialog from "./components/DelinqDialog"

// pages
import Dashboard from "./pages/Dashboard"
import ErrorPage from "./pages/ErrorPage"
import CreateTask from "./pages/CreateTask"
import Billing from "./pages/Billing"
import Support from "./pages/Support"
import Chat from "./pages/Chat"

import firebase from "./components/firebase"

const history = createBrowserHistory()

class MainApp extends Component {
  constructor(props) {
    super(props)

    this.handleShowTaskSubmitError = this.handleShowTaskSubmitError.bind(this)
    this.handleClickCB = this.handleClickCB.bind(this)
    this.openVid = this.openVid.bind(this)
  }

  state = {
    welcome: false,
    plan: null,
    showTaskSubmitError: false,
    scheduleCallLink: "https://calendly.com/pebbledsupport/30min",
    showWalkthroughVid: false,
    totalrunning: 0,
    user: {},
    // trialTaskLimit: 1,
    trialTaskLimit: 1000,
    // coreTaskLimit: 1,
    coreTaskLimit: 1000,
    // plusTaskLimit: 2,
    plusTaskLimit: 2000,
    // entTaskLimit: 4,
    entTaskLimit: 4000,
    errorType: "",
    formid: null
  }

  firstTrig = false

  componentWillMount() {
    let igloo_user = null

    try {
      igloo_user = JSON.parse(localStorage.getItem("igloo_user"))

      this.setState({
        user: igloo_user
      })
    } catch (error) {
      window.location.href = "/logout"
    }
  }

  tasks_sumitted_func = async tasks => {
    let month_we_are_in = new Date().getMonth()
    let this_month = 0
    const all = tasks.length

    for (let task of tasks) {
      let taskMonth = new Date(task.created_at).getMonth()

      if (month_we_are_in === taskMonth) {
        this_month++
      }
    }

    return { month: this_month, all: all }
  }

  tasks_completed_func = async tasks => {
    let month_we_are_in = new Date().getMonth()
    let complete_month_count = 0
    let complete_all_count = 0

    for (let task of tasks) {
      let taskMonth = new Date(task.created_at).getMonth()

      try {
        for (let custom_field of task.custom_fields) {
          if (custom_field.name === "Status") {
            let status = custom_field.enum_value.name

            if (status.toLowerCase().includes("complete")) {
              complete_all_count++

              if (month_we_are_in === taskMonth) {
                complete_month_count++
              }
            }
          }
        }
      } catch (err) {
        console.log(util.inspect(err))
      }
    }

    return {
      month: complete_month_count,
      all: complete_all_count
    }
  }

  tasks_ongoing_func = async tasks => {
    let in_progress_count = 0
    let feedback_count = 0

    try {
      for (let task of tasks) {
        try {
          for (let custom_field of task.custom_fields) {
            try {
              if (custom_field.name === "Status") {
                let status = custom_field.enum_value.name

                if (status.toLowerCase().includes("in progress")) {
                  in_progress_count++
                }

                if (status.toLowerCase().includes("ready to review")) {
                  feedback_count++
                }
              }
            } catch (errIf) {
              console.log(util.inspect(errIf))
            }
          }
        } catch (errForTaks) {
          console.log(util.inspect(errForTaks))
        }
      }
    } catch (err) {
      console.log(util.inspect(err))
    }

    return {
      inprogress: in_progress_count,
      feedback: feedback_count
    }
  }

  getTasks = async userid => {
    return firebase.db
      .collection("tasks")
      .where("userid", "==", userid)
      .get()
      .then(async function(querySnapshot) {
        let tasks = []
        querySnapshot.forEach(function(doc) {
          // console.log(doc.id, ' => ', doc.data())
          tasks.push(doc.data())
        })

        return tasks
      })
  }

  async componentDidMount() {
    try {
      firebase.isInitialized().then(val => {
        this.setState({ isInitialized: val })

        if (!val) {
          window.location.href = "/logout"
        }
      })
    } catch (error) {
      this.setState({ isInitialized: false })
      window.location.href = "/logout"
    }

    let task_info_total, totalrunning

    // try {
    //   const tasks = await this.getTasks(this.state.user.id)

    //   const tasks_responses = await Promise.all([
    //     this.tasks_sumitted_func(tasks),
    //     this.tasks_ongoing_func(tasks),
    //     this.tasks_completed_func(tasks)
    //   ])

    //   const tasks_sumitted = tasks_responses[0]
    //   const tasks_ongoing = tasks_responses[1]
    //   const tasks_completed = tasks_responses[2]
    //   const total_running =
    //     Number(tasks_sumitted.all) - Number(tasks_completed.all)

    //   const res = {
    //     submitted: tasks_sumitted,
    //     ongoing: tasks_ongoing,
    //     completed: tasks_completed,
    //     totalrunning: total_running
    //   }

    //   totalrunning = res.totalrunning
    // } catch (error) {
    //   console.log(`totalrunning error - ${error}`)
    // }

    // this.setState({
    //   totalrunning: totalrunning
    // })

    document.addEventListener("mousedown", this.handleClick, false)

    if (window.location.href.includes("welcome=true")) {
      this.setState({ welcome: true })
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false)
  }

  openVid = e => {
    // console.log('MainApp opening vid')
    this.setState({
      showWalkthroughVid: false
    })

    this.setState({
      showWalkthroughVid: true
    })
  }

  handleClick = e => {
    const self = this

    function closeWelcomeOpenIntercom() {
      self.setState({ welcome: false })
      window.Intercom("show")
      self.firstTrig = true
    }

    function closeErrorDialog() {
      self.setState({ showTaskSubmitError: false })
    }

    try {
      if (
        e.target &&
        e.target.viewportElement &&
        e.target.viewportElement.id &&
        e.target.viewportElement.id === "error-dialog"
      ) {
        // inside box
        if (e.target.id === "schedule-call-btn") {
          closeErrorDialog()
        }
      } else {
        closeErrorDialog()
      }
    } catch (error) {
      closeErrorDialog()
    }

    if (this.state.showWalkthroughVid === false) {
      if (self.firstTrig === false && self.state.welcome) {
        try {
          if (
            e.target &&
            e.target.viewportElement &&
            e.target.viewportElement.id &&
            e.target.viewportElement.id === "welcome-message"
          ) {
            // inside box
            if (
              e.target.id === "try-now-btn" ||
              e.target.id === "welcome-exit-x" ||
              e.target.id === "try-now-txt"
            ) {
              closeWelcomeOpenIntercom()
            }
          } else {
            closeWelcomeOpenIntercom()
          }
        } catch (error) {
          closeWelcomeOpenIntercom()
        }
      }
    }
  }

  runTypeform(url) {
    let popupme = window.typeformEmbed.makePopup(url, {
      hideHeaders: false,
      hideFooter: false,
      autoClose: "2"
    })

    popupme.open()
  }

  openInNewTab(url) {
    var win = window.open(url, "_blank")
    win.focus()
  }

  handleShowTaskSubmitError(data) {
    if (
      this.state.user.status === "trialing" ||
      this.state.user.plan.toLowerCase() === "core"
    ) {
      if (!data.event.currentTarget.className.includes("cat-design-item")) {
        this.setState({
          errorType: "plan",
          showTaskSubmitError: true,
          formid: data.formid
        })
      } else if (
        Number(this.state.totalrunning) >= Number(this.state.coreTaskLimit)
      ) {
        this.setState({
          errorType: "tasklimit",
          showTaskSubmitError: true,
          formid: data.formid
        })
      } else {
        this.setState({
          showTaskSubmitError: false
        })
        this.openInNewTab(
          `https://pebbled.typeform.com/to/${data.formid}?id=${this.state.user.id}`
        )
      }
    }

    if (!data.isTrial && data.plan.toLowerCase() === "plus") {
      if (Number(this.state.totalrunning) >= Number(this.state.plusTaskLimit)) {
        this.setState({
          errorType: "tasklimit",
          showTaskSubmitError: true,
          formid: data.formid
        })
      } else {
        this.setState({
          showTaskSubmitError: false
        })
        this.openInNewTab(
          `https://pebbled.typeform.com/to/${data.formid}?id=${this.state.user.id}`
        )
      }
    }

    if (!data.isTrial && data.plan.toLowerCase() === "enterprise") {
      if (Number(this.state.totalrunning) >= Number(this.state.entTaskLimit)) {
        this.setState({
          errorType: "tasklimit",
          showTaskSubmitError: true,
          formid: data.formid
        })
      } else {
        this.setState({
          showTaskSubmitError: false
        })
        this.openInNewTab(
          `https://pebbled.typeform.com/to/${data.formid}?id=${this.state.user.id}`
        )
      }
    }
  }

  handleClickCB(bool) {
    this.setState({
      showTaskSubmitError: bool
    })
  }

  closeWelcome = e => {
    this.setState({
      welcome: false
    })
  }

  videoClose = e => {
    this.setState({
      showWalkthroughVid: false
    })
  }

  handleTotalRunning = e => {
    this.setState({
      totalrunning: e
    })
  }

  render() {
    let appUser = {}

    try {
      if (this.state.user.id) {
        appUser = {
          user_id: this.state.user.id,
          email: this.state.user.email,
          name: this.state.user.name
        }
      } else {
        appUser = {}
      }
    } catch (error) {
      appUser = {}
    }

    return (
      <div>
        {this.state.isInitialized && (
          <Router history={history} basename={"/"}>
            <div className="App">
              {/* <TaskInfoDialog /> */}

              {/* <DelinqDialog /> */}

              {this.state.showWalkthroughVid && (
                <div
                  id="video-container-id"
                  className="general-dialog video-container"
                >
                  <VideoComp onVideoClose={this.videoClose} />
                </div>
              )}

              {this.state.welcome && (
                <WelcomeDialog
                  onCloseWelcome={this.closeWelcome}
                  onOpenVid={this.openVid}
                />
              )}

              {this.state.showTaskSubmitError && (
                <ErrorDialog
                  errorType={this.state.errorType}
                  onHandleClickCB={this.handleClickCB}
                />
              )}

              <Switch>
                <div className="Main-page">
                  <div className="Main-container">
                    <div className="component-container">
                      <Header
                        onHandleShowTaskSubmitError={
                          this.handleShowTaskSubmitError
                        }
                      />

                      <Switch>
                        <Route
                          path="/"
                          exact
                          strict
                          // component={Dashboard}
                          render={props => (
                            <Dashboard
                              {...props}
                              onHandleTotalRunning={this.handleTotalRunning}
                            />
                          )}
                        />

                        <Route
                          path="/create/task"
                          exact
                          strict
                          render={props => <CreateTask {...props} />}
                        />

                        <Route
                          path="/billing"
                          exact
                          strict
                          render={props => <Billing {...props} />}
                        />

                        <Route
                          path="/support"
                          exact
                          strict
                          render={props => <Support {...props} />}
                        />

                        <Route
                          path="/chat"
                          exact
                          strict
                          render={props => <Chat {...props} />}
                        />

                        <Route
                          // component={Dashboard}
                          render={props => (
                            <Dashboard
                              {...props}
                              onHandleTotalRunning={this.handleTotalRunning}
                            />
                          )}
                        />
                      </Switch>
                    </div>
                  </div>
                </div>
                <Route component={ErrorPage} />
              </Switch>
              {this.state.user && <IntercomLoad appUser={appUser} />}
            </div>
          </Router>
        )}
      </div>
    )
  }
}

export default MainApp
