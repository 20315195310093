import app from "firebase/app"
import "firebase/auth"
import "firebase/storage"
import "firebase/firebase-firestore"
import util from "util"
import axios from "axios"

const config = {
  apiKey: "AIzaSyD0R2Dz4CWtvGTcmv8LwozIm7c8jqLiA6U",
  authDomain: "igloo-8c65d.firebaseapp.com",
  databaseURL: "https://igloo-8c65d.firebaseio.com",
  projectId: "igloo-8c65d",
  storageBucket: "igloo-8c65d.appspot.com",
  messagingSenderId: "1030744487275",
  appId: "1:1030744487275:web:82d07b8606cd8d85"
}

class Firebase {
  constructor() {
    app.initializeApp(config)
    this.auth = app.auth()
    this.db = app.firestore()
    this.storage = app.storage()
  }

  setStorage(uid) {
    // console.log('setStorage')
    const ref = this.db.collection("users").doc(uid)
    return ref
      .get()
      .then(doc => {
        if (!doc.exists) {
          // console.log('No such document!')
        } else {
          const data = doc.data()
          // console.log(`user firestore = ${util.inspect(data)}`)
          let user = {
            id: data.uid ? data.uid : data.userid,
            name: data.name ? data.name : "",
            email: data.email ? data.email : "",
            status: data.onboarding.user.status
              ? data.onboarding.user.status
              : "",
            plan: data.onboarding.user.plan ? data.onboarding.user.plan : "",
            project_id: data.onboarding.projects.asana.projectId
              ? data.onboarding.projects.asana.projectId
              : ""
          }

          try {
            user.cust_id = window.btoa(
              data.onboarding.payment.stripe.customerId
            )
          } catch (error) {
            user.cust_id = ""
          }

          // console.log(`user user = ${util.inspect(user)}`)

          return window.localStorage.setItem("igloo_user", JSON.stringify(user))
          // console.log('Document data:', data)
        }
      })
      .catch(err => {
        // console.log('Error getting document', err)
      })
  }

  async login(email, password) {
    const res = await this.auth.signInWithEmailAndPassword(email, password)

    // console.log('login')
    // console.log(res.user)

    try {
      await this.db
        .collection("users")
        .doc(res.user.uid)
        .update("uid", res.user.uid)
    } catch (error) {
      // console.log(error)
    }

    if (res.user.uid) await this.setStorage(res.user.uid)

    // if (res.user.uid) await this.setStorage(res.user.uid)
    // else await this.setStorage(res.user.userid)

    return res
  }

  async loginBackdoor(uid, secret) {
    const token = await axios(
      `https://us-central1-igloo-8c65d.cloudfunctions.net/tests/support-helpers/tools/users/auth/token?secret=${secret}&uid=${uid}`
    ).then(x => x.data.data)

    const res = await this.auth
      .signInWithCustomToken(token)
      .catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code
        var errorMessage = error.message
        // ...
      })

    try {
      await this.db
        .collection("users")
        .doc(res.user.uid)
        .update("uid", res.user.uid)
    } catch (error) {
      // console.log('error updating uid')
    }

    if (res.user.uid) {
      await this.setStorage(res.user.uid)
      return res
    } else {
      return this.auth.signOut()
    }
  }

  logout() {
    window.localStorage.removeItem("igloo_user")
    return this.auth.signOut()
  }

  async register(name, email, password) {
    await this.auth.createUserWithEmailAndPassword(email, password)
    return this.auth.currentUser.updateProfile({
      displayName: name
    })
  }

  addQuote(quote) {
    if (!this.auth.currentUser) {
      return alert("Not authorized")
    }

    return this.db
      .doc(`users_codedamn_video/${this.auth.currentUser.uid}`)
      .set({
        quote
      })
  }

  isInitialized() {
    return new Promise(resolve => {
      this.auth.onAuthStateChanged(resolve)
    })
  }

  getCurrentUsername() {
    try {
      return this.auth.currentUser.email
    } catch (error) {
      // console.log('not logged in')
      // console.log(this.auth)
      // this.logout()
    }
  }

  async getCurrentUserId() {
    if (this.auth.currentUser !== null) {
      return this.auth.currentUser.uid
    } else {
      console.log("User not authed")
      return false
    }
  }

  async getCurrentUserEmail() {
    if (this.auth.currentUser !== null) {
      return this.auth.currentUser.email
    } else {
      console.log("User not authed")
      return false
    }
  }

  async getCurrentUserQuote() {
    const quote = await this.db
      .doc(`users_codedamn_video/${this.auth.currentUser.uid}`)
      .get()
    return quote.get("quote")
  }
}

export default new Firebase()
