import React, { Component } from 'react'
import util from 'util'
import axios from 'axios'
import fetch from 'isomorphic-fetch'
import DropboxWrapper from 'dropbox'

const dbx = new DropboxWrapper.Dropbox({
  accessToken:
    'KtlZdybf4rkAAAAAAACPEIKTBUyQ55G3t7ng9TjBZGIQuBXLLhpV_KGjV1N4yezb',
  fetch: fetch
})

class DropBoxer extends Component {
  constructor(props) {
    super(props)
  }

  state = {
    dropboxFiles: {}
  }

  componentDidMount() {
    console.log('DropBoxer componentDidMount')
    // dbx
    //   .filesListFolder({ path: '' })
    //   .then(function(response) {
    //     console.log(util.inspect(response))
    //     // this.setState({ dropboxFiles: response })
    //   })
    //   .catch(function(error) {
    //     console.log(util.inspect(error))
    //     // this.setState({ dropboxFiles: error })
    //   })
  }

  listFiles = () => {
    return dbx
      .filesListFolder({ path: '/testupload' })
      .then(function(response) {
        console.log('response', response)
        // displayFiles(response.entries)
        // console.log(response)

        try {
          return response.entries
        } catch (error) {
          return response
        }
      })
      .catch(function(error) {
        console.error(error)
        return error
      })
  }

  delete = () => {
    //
  }

  getLink = () => {
    //
  }

  upload = fileObj => {
    console.log('DropBoxer test')
    console.log(util.inspect(dbx))
    console.log('fileObj')
    console.log(util.inspect(fileObj))
    console.log(fileObj.localSrc)

    return dbx
      .filesUpload({
        path: '/testupload/' + fileObj.file.name,
        mode: 'add',
        autorename: true,
        mute: false,
        strict_conflict: false,
        contents: fileObj.file
      })
      .then(function(response) {
        console.log(util.inspect(response, { depth: 6 }))
        // this.setState({ dropboxFiles: response })
      })
      .catch(function(error) {
        console.log(util.inspect(error, { depth: 6 }))
        // this.setState({ dropboxFiles: error })
      })

    // return dbx
    //   .filesListFolder({ path: '' })
    //   .then(function(response) {
    //     console.log(util.inspect(response))
    //     // this.setState({ dropboxFiles: response })
    //   })
    //   .catch(function(error) {
    //     console.log(util.inspect(error))
    //     // this.setState({ dropboxFiles: error })
    //   })
  }

  render() {
    const dropboxFiles = 'HI'

    return <div className="hideme">{/* <div>{dropboxFiles}</div> */}</div>
  }
}

export default DropBoxer
